import React from 'react';
import { IError, IErrorType } from '../../types/models';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import * as S from './index.styles';

const PatientError = ({ type, message }: IError) => (
    <>
        {type === IErrorType.APPOINTMENT_MISSING_STATUS && <S.ErrorIcon icon={faCircleInfo as IconProp} />}
        {type === IErrorType.APPOINTMENT_NOT_BOOKED && <S.ErrorIcon criticalIssue icon={faCircleInfo as IconProp} />}
        {type === IErrorType.CHARTING_NOTE_NOT_LOCKED && <S.ErrorIcon criticalIssue icon={faCircleInfo as IconProp} />}
        {type === IErrorType.NO_CCP_CREATED && <S.ErrorIcon icon={faCircleInfo as IconProp} />}
        {type === IErrorType.WRONG_APPOINTMENT_LENGTH && <S.ErrorIcon criticalIssue icon={faCircleInfo as IconProp} />}
        {type === IErrorType.EXERCISE_PLAN_ENDED && <S.ErrorIcon icon={faCircleInfo as IconProp} />}
        {type === IErrorType.PT_SPECIFIC_CHARTING_NOTE_NOT_LOCKED && <S.ErrorIcon icon={faCircleInfo as IconProp} />}
        {type === IErrorType.RRT_SPECIFIC_CHARTING_NOTE_NOT_LOCKED && <S.ErrorIcon icon={faCircleInfo as IconProp} />}
        {' '}{message}
    </>
);

export default PatientError;
