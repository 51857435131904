import { Auth } from 'aws-amplify';
import { createSlice } from '@reduxjs/toolkit';
import { Routes } from '../../../api/Routes';
import { ICoach, IPatientListUser, IPatient, IPatientError, IUser, UserAssignment, PatientStatus, IErrorType } from '../../../types/models';
import { AppDispatch } from '../../store';
import { TenantApi } from '../../../api/CoachingAPI';
import { CriticalErrorTypes, ErrorMessages } from '../../../pages/PatientsList/components/tableUtils';

export interface CoachingState {
    coaches: ICoach[] | [];
    patientListUsers: IPatientListUser[] | [];
    totalPatients: number;
    currentPatient: IPatient | null;
    patientErrors: IPatientError[];
    patientTotalErrors: number;
    patientTotalCriticalErrors: number;
    patientTotalStandardErrors: number;
}

const initialState = {
    coaches: [],
    currentPatient: null,
    patientListUsers: [],
    totalPatients: 0,
    patientErrors: [],
    patientTotalErrors: 0,
    patientTotalCriticalErrors: 0,
    patientTotalStandardErrors: 0,
} as CoachingState;

const sessionSlice = createSlice({
    name: 'coaching',
    initialState,
    reducers: {
        setCoaches_: (state, { payload }: {payload: { coaches: ICoach[] }}) => {
            state.coaches = payload.coaches;
        },
        setPatientListUsers: (state, { payload }: {payload: { patientListUsers : IPatientListUser[], totalPatients: number }}) => {
            state.patientListUsers = payload.patientListUsers;
            state.totalPatients = payload.totalPatients;
        },
        setCurrentPatient: (state, { payload }: {payload: { patient: IPatient | null }}) => {
            state.currentPatient = payload.patient;
        },
        setPatientErrors: (state, { payload }: {payload: { patientErrors: IPatientError[] }}) => {
            state.patientErrors = payload.patientErrors;
            state.patientTotalErrors = payload.patientErrors.reduce((count, patient) => count + patient.errors.length, 0);
            state.patientTotalCriticalErrors = payload.patientErrors.reduce((count, patient) => {
                return (
                    count + patient.errors.filter((error) => CriticalErrorTypes.includes(error.type)).length
                );
            }, 0);
            state.patientTotalStandardErrors = state.patientTotalErrors - state.patientTotalCriticalErrors;
        },
    }
});

export const {
    setCoaches_,
    setPatientListUsers,
    setCurrentPatient,
    setPatientErrors,
} = sessionSlice.actions;

export default sessionSlice.reducer;

export type PatientsFetchParams = {
    page: number;
    pageSize: number;
    search?: string;
    orderBy?: string;
    sortBy?: string;
    assignmentType?: 'PRIMARY' | 'OTHER';
    nextRrtAppointment?: string;
    nextPtAppointment?: string;
    ptProgramStatus: string[];
    rrtProgramStatus: string[];
    programStatusInclusion: 'AND' | 'OR';
}

export const fetchPatients = ({
    page,
    pageSize,
    search,
    orderBy,
    sortBy,
    assignmentType,
    nextPtAppointment,
    nextRrtAppointment,
    ptProgramStatus,
    rrtProgramStatus,
    programStatusInclusion,
}: PatientsFetchParams) => async (dispatch: AppDispatch) => {
    let url = `/${Routes.coaches}/${Routes.patients}/summary?page=${page}&pageSize=${pageSize}`;
    if (orderBy) {
        url += `&orderBy=${orderBy}`;
    }
    if (sortBy) {
        url += `&sortBy=${sortBy}`;
    }
    if (search) {
        url += `&search=${search}`;
    }
    if (assignmentType) {
        url += `&assignmentType=${assignmentType}`;
    }
    if (nextPtAppointment) {
        url += `&ptAppointmentScheduledOccurredDate=${nextPtAppointment}`;
    }
    if (nextRrtAppointment) {
        url += `&rrtAppointmentScheduledOccurredDate=${nextRrtAppointment}`;
    }
    if (ptProgramStatus.length > 0) {
        url += `&ptProgramStatus=${ptProgramStatus.join(',')}`;
    }
    if (rrtProgramStatus.length > 0) {
        url += `&rrtProgramStatus=${rrtProgramStatus.join(',')}`;
    }
    if (programStatusInclusion) {
        url += `&programStatusInclusion=${programStatusInclusion}`;
    }
    let patients = await TenantApi.get(url);
    if (!patients) {
        patients = {
            content: [],
            totalElements: 0
        };
    }
    dispatch(setPatientListUsers({ patientListUsers: patients.content, totalPatients: patients.totalElements }));
};

export const fetchCoaches = () => async (dispatch: AppDispatch) => {
    const coaches = await TenantApi.get(`/${Routes.coaches}/summary`);
    dispatch(setCoaches_({ coaches }));
};

export const fetchPatient = (userId: string) => async (dispatch: AppDispatch) => {
    const fetchedPatient = await TenantApi.get(`/${Routes.coaches}/patients/${userId}`);
    dispatch(setCurrentPatient({ patient: fetchedPatient }));
};

export const setActivePatient = (patient: IPatient) => async (dispatch: AppDispatch) => {
    dispatch(setCurrentPatient({ patient }));
};

export const clearCurrentPatient = () => async (dispatch: AppDispatch) => {
    dispatch(setCurrentPatient({ patient: null }));
};

export const patchUserAssignment = ({ patientId, userAssignment }: {patientId: string, userAssignment: UserAssignment}) => async (dispatch: AppDispatch) => {
    await TenantApi.patch(`/${Routes.coaches}/${Routes.users}/${patientId}`, userAssignment).then(
        () => dispatch(fetchPatient(patientId))
    );
};

export const fetchErrors = () => async (dispatch: AppDispatch) => {
    const errors = await TenantApi.get(`/${Routes.coaches}/errors`);
    dispatch(setPatientErrors({ patientErrors: errors }));
};
