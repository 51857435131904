import React from 'react';
import { IPatient, CareType } from '../../../../types/models';
import * as S from './index.styles';
import PatientStatusTag from '../../../../components/PatientStatusTag';

type Props = {
    patient: IPatient;
    children?: React.ReactNode;
}

const PatientPageHeader = ({ children, patient }: Props) => {
    const formatPhoneNumber = (phoneNumber: string) => {
        if (!phoneNumber) {
            return null;
        }
        const cleaned = phoneNumber.replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return null;
    };
    return (
        <S.PageHeader>
            <S.PatientInfo>
                <S.HeaderRow>
                    <S.HeaderName>
                        {/* TODO: Find a better way to hide patient given name based on full legal name */}
                        {patient.healthieFirstName} {(patient.firstName !== patient.healthieFirstName || !patient.fullLegalName?.includes(patient.firstName)) && `(${patient.firstName})`} {patient.healthieLastName}
                    </S.HeaderName>
                    {patient.ptProgramStatus
                        && (
                        <PatientStatusTag
                          patientStatus={patient.ptProgramStatus}
                          careType={CareType.PT}
                        />
                    )}
                    {patient.rrtProgramStatus
                        && (
                        <PatientStatusTag
                          patientStatus={patient.rrtProgramStatus}
                          careType={CareType.RRT}
                        />
                    )}
                </S.HeaderRow>
                <S.FullLegalName>{patient.fullLegalName}</S.FullLegalName>
                <S.ContactInfo>
                    <S.ContactItem>{patient.email}</S.ContactItem>
                    <S.ContactItem>{formatPhoneNumber(patient.phoneNumber)}</S.ContactItem>
                </S.ContactInfo>
            </S.PatientInfo>
            <S.Actions>
                {children}
            </S.Actions>
        </S.PageHeader>
    );
};

export default PatientPageHeader;
