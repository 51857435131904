import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { useParams } from 'react-router';
import { IBillableSession, IBillableSessionCreationResponse, PatientStatus, UserRoles } from '../../../../types/models';
import toast from 'react-hot-toast';
import { differenceInSeconds } from 'date-fns';
import { useActionLoader } from '../../../../hooks/useActionLoader';
import { ApiResponse, RequestMethod, useApiRequest } from '../../../../hooks/useApiRequest';
import { fetchBillableSessionTotalSummary } from '../../../../state/reducers/billing';
import { isPTDischarged } from '../../patientUtils';

export enum TimerState {
  NOT_STARTED = 'NOT_STARTED',
  RUNNING = 'RUNNING',
  STOPPED = 'STOPPED',
  MANUALLY_STOPPED = 'MANUALLY_STOPPED',
}

export enum TimerAction {
  START = 'START',
  STOP = 'STOP',
  MANUALLY_STOP = 'MANUALLY_STOP',
  RESTART = 'RESTART',
}

export const useTimerStateMachine = (initialState: TimerState) => {
    const { user: provider } = useSelector((state: RootState) => state.session);
    const [time, setTime] = useState(0);
    const [finalTime, setFinalTime] = useState(0);
    const [startTime, setStartTime] = useState(new Date());
    const [lastUpdateTime, setLastUpdateTime] = useState(new Date());
    // need this ref to get an accurate start time when creating a billable session
    // upon navigating away from the patient profile page
    const startTimeRef = useRef(startTime);
    const [timer, setTimer] = useState<ReturnType<typeof setInterval> | null>(null);
    const { patientId } = useParams<{ patientId: string }>();
    const sessionId = useRef<number | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [popupInterval, setPopupInterval] = useState(0);
    const [stopInterval, setStopInterval] = useState(0);
    const [userRole, setUserRole] = useState<string | undefined>();
    const { callAction: getTotalSummary } = useActionLoader(fetchBillableSessionTotalSummary);
    const { callApi: createSession } = useApiRequest<IBillableSessionCreationResponse>(RequestMethod.POST);
    const { callApi: updateSession } = useApiRequest<IBillableSession>(RequestMethod.PUT);
    const { currentPatient } = useSelector((store: RootState) => store.coaching);

    const createTimerEntry = async () => {
        if (!provider || !patientId) {
            return;
        }
        const body: IBillableSession = {
            userId: patientId,
            providerId: provider.id,
            providerName: `${provider.firstName} ${provider.lastName}`,
            startDate: startTimeRef.current,
            endDate: new Date(),
        };

        // send create timer event to backend
        await createSession(`/users/${patientId}/billable-sessions/timer-sessions`, body).then(({ response, error }: ApiResponse<IBillableSessionCreationResponse>) => {
            if (!!error.error || !!error.message) {
                toast.error('Error creating timer entry');
            } else {
                sessionId.current = response.data.id;
                setPopupInterval(response.data.timerPopupInterval);
                setStopInterval(response.data.stopTimerInterval);
                setLastUpdateTime(new Date());
                getTotalSummary(patientId);
            }
        }).catch((err) => {
            toast.error('Error creating timer entry');
        });
    };

    const updateTimerEntry = async () => {
        const endTime = new Date();
        if (!provider || !patientId || !sessionId.current) {
            return;
        }
        const body: IBillableSession = {
            userId: patientId,
            providerId: provider.id,
            providerName: `${provider.firstName} ${provider.lastName}`,
            startDate: startTimeRef.current,
            endDate: endTime,
        };

        // send update timer event to backend
        await updateSession(`/users/${patientId}/billable-sessions/timer-sessions/${sessionId.current}`, body).then(({ response, error }: ApiResponse<IBillableSession>) => {
            if (!!error.error || !!error.message) {
                toast.error('Error updating timer entry');
            } else {
                setFinalTime(differenceInSeconds(new Date(response.data.endDate), new Date(response.data.startDate)));
                setLastUpdateTime(new Date());
                getTotalSummary(patientId);
            }
        }).catch((err) => {
            toast.error('Error updating timer entry');
        });
    };

    const createTimerInterval = () => {
        if (timer === null) {
            setTimer(setInterval(() => {
                setTime(Math.floor(differenceInSeconds(new Date(), startTimeRef.current)));
            }, 1000));
        }
    };

    // Timer state machine, this makes the code a little cleaner and easier to read
    // For more information on the state machine, see this Notion doc:
    // https://www.notion.so/breathesuite/Billing-Session-Timer-flow-3405833c502a4ee1981644db0d82eedc?pvs=4
    const reducer = (lastState: TimerState, action: {type: TimerAction }) => {
        switch (lastState) {
            case TimerState.NOT_STARTED:
                switch (action.type) {
                    case TimerAction.START:
                        createTimerInterval();
                        setLastUpdateTime(new Date());
                        return TimerState.RUNNING;
                    case TimerAction.MANUALLY_STOP:
                        return TimerState.MANUALLY_STOPPED;
                    default:
                        return lastState;
                }
            case TimerState.RUNNING:
                switch (action.type) {
                    case TimerAction.STOP:
                        if (timer !== null) {
                            clearInterval(timer);
                            setTimer(null);
                            setTime(0);
                        }
                        setLoading(true);
                        if (sessionId.current) {
                            updateTimerEntry().then(() => {
                                sessionId.current = undefined;
                            }).finally(() => {
                                setLoading(false);
                            });
                        } else if (differenceInSeconds(new Date(), startTimeRef.current) <= 10) {
                            // A session should only be created if the timer was running for 10 seconds or less
                            // Once a timer has ran for more than 10 seconds, the sessionId should be defined
                            createTimerEntry().then(() => {
                                if (timer !== null) {
                                    sessionId.current = undefined;
                                }
                            }).finally(() => {
                                setLoading(false);
                            });
                        }
                        return TimerState.STOPPED;
                    case TimerAction.MANUALLY_STOP:
                        if (timer !== null) {
                            clearInterval(timer);
                            setTimer(null);
                            setTime(0);
                        }
                        setLoading(true);
                        if (sessionId.current) {
                            updateTimerEntry().then(() => {
                                if (timer !== null) {
                                    sessionId.current = undefined;
                                }
                            }).finally(() => {
                                setLoading(false);
                            });
                        } else {
                            createTimerEntry().then(() => {
                                if (timer !== null) {
                                    sessionId.current = undefined;
                                }
                            }).finally(() => {
                                setLoading(false);
                            });
                        }
                        return TimerState.MANUALLY_STOPPED;
                    default:
                        return lastState;
                }
            case TimerState.STOPPED:
                switch (action.type) {
                    case TimerAction.START:
                        setStartTime(new Date());
                        setLastUpdateTime(new Date());
                        createTimerInterval();
                        return TimerState.RUNNING;
                    case TimerAction.MANUALLY_STOP:
                        return TimerState.MANUALLY_STOPPED;
                    default:
                        return lastState;
                }

            // Manually stop is manual where stop is when focus is lost.
            // This fixes the issue with the timer getting resumed by refocusing the page
            case TimerState.MANUALLY_STOPPED:
                switch (action.type) {
                    case TimerAction.RESTART:
                        setStartTime(new Date());
                        setLastUpdateTime(new Date());
                        createTimerInterval();
                        return TimerState.RUNNING;
                    default:
                        return lastState;
                }
            default:
                return lastState;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);
    const stateRef = useRef<TimerState>(initialState);

    const startTimer = () => {
        dispatch({ type: TimerAction.START });
    };

    const stopTimer = () => {
        dispatch({ type: TimerAction.STOP });
    };

    const manuallyStopTimer = () => {
        dispatch({ type: TimerAction.MANUALLY_STOP });
    };

    const restartTimer = () => {
        dispatch({ type: TimerAction.RESTART });
    };

    const timerActions = {
        startTimer,
        stopTimer,
        manuallyStopTimer,
        restartTimer,
    };

    useEffect(() => {
        // every 10 seconds, update time in backend
        if (state === TimerState.RUNNING && !sessionId.current && differenceInSeconds(new Date(), lastUpdateTime) >= 10) {
            createTimerEntry();
        } else if (state === TimerState.RUNNING && time !== 0 && differenceInSeconds(new Date(), lastUpdateTime) >= 10) {
            updateTimerEntry();
        }
    }, [time]);

    useEffect(() => {
        // don't initialize timer if user has no billable role and currentPatient is not discharged
        if (userRole !== undefined) {
            if (userRole === UserRoles.PHYSICAL_THERAPIST && !isPTDischarged(currentPatient)) {
                window.addEventListener('focus', startTimer);
                window.addEventListener('blur', stopTimer);
                // start timer when component mounts if window is focused
                if (document.hasFocus()) {
                    startTimer();
                }
            } else {
                manuallyStopTimer();
            }
        }
        // clean up event listeners when component unmounts
        return () => {
            if (userRole === UserRoles.PHYSICAL_THERAPIST) {
                window.removeEventListener('focus', startTimer);
                window.removeEventListener('blur', stopTimer);
            }
        };
    }, [userRole]);

    useEffect(() => {
        return () => {
            // if user navigates away from page where this component is present, update time in backend
            const tearDown = async () => {
                if (sessionId.current) {
                    await updateTimerEntry();
                } else if (stateRef.current === TimerState.RUNNING) {
                    await createTimerEntry();
                }
                if (timer) {
                    clearInterval(timer);
                }
            };
            tearDown();
        };
    }, []);

    useEffect(() => {
        startTimeRef.current = startTime;
    }, [startTime]);

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    useEffect(() => {
        setUserRole(provider?.roles.find(role => role.billable)?.role);
    }, [provider]);

    return {
        state,
        dispatch,
        time,
        finalTime,
        timerActions,
        loading,
        popupInterval,
        stopInterval
    };
};
