import { IPatient, PatientStatus } from '../../types/models';

export const isPTDischarged = (patient: IPatient | null): boolean => {
    if (patient?.ptProgramStatus === PatientStatus.VPR_DISCHARGED
        || patient?.ptProgramStatus === PatientStatus.MNT_DISCHARGED) {
        return true;
    }
    return false;
};

export const isRRTDischarged = (patient: IPatient | null): boolean => {
    if (patient?.rrtProgramStatus === PatientStatus.VPR_DISCHARGED
        || patient?.rrtProgramStatus === PatientStatus.MNT_DISCHARGED) {
        return true;
    }
    return false;
};

export const isPTMaintenance = (patient: IPatient | null): boolean => {
    if (patient?.ptProgramStatus === PatientStatus.MNT_ACTIVE
        || patient?.ptProgramStatus === PatientStatus.MNT_PAUSED) {
        return true;
    }
    return false;
};
export const isRRTMaintenance = (patient: IPatient | null): boolean => {
    if (patient?.rrtProgramStatus === PatientStatus.MNT_ACTIVE
        || patient?.rrtProgramStatus === PatientStatus.MNT_PAUSED) {
        return true;
    }
    return false;
};

export const isPTPaused = (patient: IPatient | null): boolean => {
    if (patient?.ptProgramStatus === PatientStatus.VPR_PAUSED
        || patient?.ptProgramStatus === PatientStatus.MNT_PAUSED) {
        return true;
    }
    return false;
};

export const isRRTPaused = (patient: IPatient | null): boolean => {
    if (patient?.rrtProgramStatus === PatientStatus.VPR_PAUSED
        || patient?.rrtProgramStatus === PatientStatus.MNT_PAUSED) {
        return true;
    }
    return false;
};

export const isPTActive = (patient: IPatient | null): boolean => {
    if (patient?.ptProgramStatus === PatientStatus.VPR_ACTIVE
        || patient?.ptProgramStatus === PatientStatus.MNT_ACTIVE) {
        return true;
    }
    return false;
};

export const isRRTActive = (patient: IPatient | null): boolean => {
    if (patient?.rrtProgramStatus === PatientStatus.VPR_ACTIVE
        || patient?.rrtProgramStatus === PatientStatus.MNT_ACTIVE) {
        return true;
    }
    return false;
};
