import styled from 'styled-components';

export const Img = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    img, svg, png {
        width: 100%;
        height: 100%;
    }
`;

export const LinkRow = styled.a`
    display: flex;
    align-items: center;
`;

export const LinkArrow = styled.div`
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 100%;
        height: 100%;
    }
`;

export const LinkText = styled.p`
    margin: 0px 10px;
    padding: 0px;
    font-weight: bold;
    text-decoration: none;
`;
