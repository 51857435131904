import React, { useEffect, useState } from 'react';
import * as SParent from '../../index.styles';
import * as S from './index.styles';
import { UserRoles, IPatient, IErrorType, IError } from '../../../../../../types/models';
import { useModal, constants, Buttons, Spinner } from '@apps/common-ui';
import PaymentAccessModal from './components/PaymentAccessModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../state/store';
import { fetchErrors, patchUserAssignment } from '../../../../../../state/reducers/coaching';
import PatientError from '../../../../../../components/PatientErrorList';
import { useActionLoader } from '../../../../../../hooks/useActionLoader';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ReactComponent as Pencil } from '../../../../../../assets/pencil.svg';

const ErrorAndBillingRow = () => {
    const { callAction: getAllPatientsErrors, loading } = useActionLoader(fetchErrors);
    const { callAction: updatePatient } = useActionLoader(patchUserAssignment);
    const { currentPatient: patient, patientErrors } = useSelector((state: RootState) => state.coaching);
    const { userBillableRole: role } = useSelector((state: RootState) => state.session);
    const [currentPatientErrors, setCurrentPatientErrors] = useState<IError[]>([]);
    const [access, setAccess] = useState(false);
    const { isOpen, openModal, closeModal } = useModal();

    const handleAccess = (choice: boolean) => {
        let groupCode = '';
        if (choice && patient) {
            groupCode = constants.USER_GROUP_CODES.healthieBilling;
        }
        const userAssignment = {
            groupCode,
        };
        updatePatient({ patientId: patient?.id, userAssignment });
        setAccess(choice);
    };

    const getErrors = () => {
        if (patient && role) {
            getAllPatientsErrors(patient.id);
        }
    };

    useEffect(() => {
        setAccess(patient?.groupCode?.includes(constants.USER_GROUP_CODES.healthieBilling) || false);
        getErrors();
    }, [patient]);

    useEffect(() => {
        if (patient) {
            const index = patientErrors.findIndex(p => p.id === patient.id);
            if (index !== -1) {
                setCurrentPatientErrors(patientErrors[index].errors);
            }
        }
    }, [patient, patientErrors]);

    return (
        <>
            {isOpen && (
                <PaymentAccessModal onClose={closeModal} handleSelect={handleAccess} enabled={patient?.groupCode?.includes(constants.USER_GROUP_CODES.healthieBilling) || false} />
            )}
            <SParent.PatientInfoRow>
                <S.ErrorsContainer>
                    <S.HeaderRow>
                        <S.Label type="errors">Errors to address</S.Label>
                        {role && (
                            <Buttons.Button
                              buttonType="tertiary"
                              onClick={getErrors}
                            >
                                <S.StyledSpinner loading={loading} icon={faSync as IconProp} />
                                Refresh
                            </Buttons.Button>
                        )}
                    </S.HeaderRow>
                    {!loading && currentPatientErrors.map((error) => (
                        <S.Error key={error.type}>
                            <PatientError type={error.type} message={error.message} />
                        </S.Error>
                    ))}
                    {!loading && currentPatientErrors.length === 0 && role && (
                        <p>No errors to address</p>
                    )}
                    {!loading && !role && (
                        <p>Errors are role specific and you do not have a billable role</p>
                    )}
                </S.ErrorsContainer>
                <S.InsuranceContainer>
                    <S.Label>Insurance & Billing</S.Label>
                    {role === UserRoles.PHYSICAL_THERAPIST && (
                        <>
                            <S.RowItems>
                                <S.Item>Max PT Appointment Length</S.Item>
                                <S.Item size="small"><strong>{patient?.flatRateBilling ? 45 : 60} min</strong></S.Item>
                            </S.RowItems>
                            <S.RowItems>
                                <S.Item>Max RTM Units</S.Item>
                                <S.Item size="small"><strong>{patient?.flatRateBilling ? 2 : 4} units</strong></S.Item>
                            </S.RowItems>
                        </>
                    )}
                    <S.RowItems>
                        <S.Item>Access To Healthie Payment</S.Item>
                        <S.Item size="small">
                            {access
                                ? (
                                    <S.PencilButton
                                      buttonType="tertiary"
                                      onClick={openModal}
                                      color="green"
                                    >
                                        <strong>Yes</strong><Pencil aria-label="pencil" />
                                    </S.PencilButton>
                                )
                                : (
                                    <S.PencilButton
                                      buttonType="tertiary"
                                      onClick={openModal}
                                      color="red"
                                    >
                                        <strong>No</strong><Pencil aria-label="pencil" />
                                    </S.PencilButton>
                                )}
                        </S.Item>
                    </S.RowItems>
                </S.InsuranceContainer>
            </SParent.PatientInfoRow>
        </>
    );
};

export default ErrorAndBillingRow;
