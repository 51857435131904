import React, { useEffect, useState } from 'react';
import { Modal, Inputs } from '@apps/common-ui';
import * as S from './index.styles';
import { IMaintenanceRecommendation, MaintenanceRecommendationOptions, MaintenanceRecommendationType, UserRoles } from '../../../../../../../types/models';
import { fetchPatient } from '../../../../../../../state/reducers/coaching';
import { useActionLoader } from '../../../../../../../hooks/useActionLoader';
import { RequestMethod, useApiRequest } from '../../../../../../../hooks/useApiRequest';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../state/store';
import { MaintenanceRecVisitLengthMinutesOptions, VisitFrequencyOptions } from '@apps/common-utilities/src/types/maintenanceRecommendation';
import { maintenanceRecVisitFrequencyOptionsDisplay, maintenanceRecVisitLengthMinutesDisplay } from '@apps/common-utilities/src/types/labels';

interface Props {
    showModal: boolean;
    dismissModal: () => void;
    formData: IMaintenanceRecommendation | null;
    userBillableRole: UserRoles | null;
}

const visitLengthUnit = 'min';

const maintenanceRecIsRecommendedMapping = [
    { display: MaintenanceRecommendationOptions.Yes, value: true },
    { display: MaintenanceRecommendationOptions.No, value: false },
    { display: MaintenanceRecommendationOptions.Undetermined, value: null }
];

const maintenanceRecVisitFrequencyOptionsMapping = [
    { display: maintenanceRecVisitFrequencyOptionsDisplay(VisitFrequencyOptions.BI_WEEKLY), value: VisitFrequencyOptions.BI_WEEKLY },
    { display: maintenanceRecVisitFrequencyOptionsDisplay(VisitFrequencyOptions.TRI_WEEKLY), value: VisitFrequencyOptions.TRI_WEEKLY },
    { display: maintenanceRecVisitFrequencyOptionsDisplay(VisitFrequencyOptions.MONTHLY), value: VisitFrequencyOptions.MONTHLY }
];

const maintenanceRecVisitLengthMinutesMapping = [
    { display: `${maintenanceRecVisitLengthMinutesDisplay(MaintenanceRecVisitLengthMinutesOptions.THIRTY)} ${visitLengthUnit}`, value: maintenanceRecVisitLengthMinutesDisplay(MaintenanceRecVisitLengthMinutesOptions.THIRTY) },
    { display: `${maintenanceRecVisitLengthMinutesDisplay(MaintenanceRecVisitLengthMinutesOptions.FORTYFIVE)} ${visitLengthUnit}`, value: maintenanceRecVisitLengthMinutesDisplay(MaintenanceRecVisitLengthMinutesOptions.FORTYFIVE) },
    { display: `${maintenanceRecVisitLengthMinutesDisplay(MaintenanceRecVisitLengthMinutesOptions.SIXTY)} ${visitLengthUnit}`, value: maintenanceRecVisitLengthMinutesDisplay(MaintenanceRecVisitLengthMinutesOptions.SIXTY) }
];

const MaintenanceRecommendationModal = ({ showModal, dismissModal, formData, userBillableRole }: Props) => {
    const { currentPatient } = useSelector((store: RootState) => store.coaching);
    const { callAction: getPatient, done: PatientDone, loading: PatientLoading } = useActionLoader(fetchPatient);

    const { callApi: createRecommendation, done: createRecommendationDone } = useApiRequest<IMaintenanceRecommendation>(RequestMethod.POST);
    const { callApi: updateRecommendation, done: updateRecommendationDone } = useApiRequest<IMaintenanceRecommendation>(RequestMethod.PUT);
    const { callApi: deleteRecommendation, done: deleteRecommendationDone } = useApiRequest<IMaintenanceRecommendation>(RequestMethod.DELETE);

    const [formValues, setFormValues] = useState<IMaintenanceRecommendation>(formData
        ? { ...formData }
        : {
            maintenanceRecommendationType: userBillableRole === UserRoles.PHYSICAL_THERAPIST ? MaintenanceRecommendationType.PT : MaintenanceRecommendationType.PCM,
            recommended: null,
            visitFrequency: VisitFrequencyOptions.MONTHLY,
            visitLengthMinutes: null,
            durationMonths: null,
        });

    useEffect(() => {
        if (formValues.recommended && formValues.visitFrequency == null) {
            setFormValues((prevValues) => ({
                ...prevValues,
                visitFrequency: VisitFrequencyOptions.MONTHLY,
            }));
        }
    }, [formValues]);

    const updateForm = (field: keyof IMaintenanceRecommendation, value: string | number | boolean | null) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [field]: value,
        }));
    };

    const handleSave = async () => {
        let res;
        if (formValues.recommended === null && formData) {
            res = await deleteRecommendation(`/coaches/users/${currentPatient?.id}/maintenanceRecommendation/${formValues.id}`);
        } else if (formValues.id) {
            res = await updateRecommendation(`/coaches/users/${currentPatient?.id}/maintenanceRecommendation/${formValues.id}`, formValues);
        } else if (!formData && formValues.recommended !== null) {
            res = await createRecommendation(`/coaches/users/${currentPatient?.id}/maintenanceRecommendation`, formValues);
        }
        if (res?.error?.message) {
            toast.error(res.error.message);
            return;
        }
        dismissModal();
        getPatient(currentPatient?.id);
    };

    const isFormComplete = formValues.recommended !== undefined
    && (formValues.recommended === null
        || !formValues.recommended
        || (formValues.recommended
            && formValues.visitFrequency != null
            && formValues.visitLengthMinutes != null
            && (userBillableRole === UserRoles.PHYSICAL_THERAPIST ? formValues.durationMonths != null : true))
    );

    return (
        <Modal showModal={showModal} dismissModal={dismissModal} title={(userBillableRole === UserRoles.PHYSICAL_THERAPIST) ? 'PT Maintenance Recommendation' : 'PCM Maintenance Recommendation'} minWidth="800px">
            <S.FormSection>
                <S.FormField>
                    <S.QuestionLabel>
                        Are you recommending this patient
                        {(userBillableRole === UserRoles.PHYSICAL_THERAPIST) ? ' do maintenance PT?' : ' continue long term in PCM?'}
                    </S.QuestionLabel>
                    <Inputs.OptionSelector
                      options={maintenanceRecIsRecommendedMapping}
                      onSelect={(selectedOption) => { updateForm('recommended', selectedOption); }}
                      width="40%"
                      value={formValues.recommended}
                    />
                </S.FormField>

                {formValues.recommended && (
                    <>
                        <S.FormField>
                            <S.QuestionLabel>
                                What is the intended plan during maintenance
                                {(userBillableRole === UserRoles.PHYSICAL_THERAPIST) ? ' PT' : ' PCM'}
                                ?
                            </S.QuestionLabel>
                            <Inputs.OptionSelector
                              options={maintenanceRecVisitFrequencyOptionsMapping}
                              onSelect={(selectedOption) => { updateForm('visitFrequency', selectedOption); }}
                              width="50%"
                              value={formValues.visitFrequency}
                            />
                        </S.FormField>

                        <S.FormField>
                            <S.QuestionLabel>Visit Length?</S.QuestionLabel>
                            <Inputs.OptionSelector
                              options={maintenanceRecVisitLengthMinutesMapping}
                              onSelect={(selectedOption) => updateForm('visitLengthMinutes', selectedOption)}
                              width="40%"
                              value={formValues.visitLengthMinutes}
                            />
                        </S.FormField>

                        {(userBillableRole === UserRoles.PHYSICAL_THERAPIST) && (
                            <S.FormField>
                                <S.QuestionLabel>Maintenance PT Duration in Months?</S.QuestionLabel>
                                <S.MonthsSelect
                                  name="durationMonths"
                                  value={formValues.durationMonths ?? ''}
                                  onChange={(e) => {
                                    const value = e.target.value === '' ? null : parseInt(e.target.value, 10);
                                    setFormValues({ ...formValues, durationMonths: value });
                                }}
                                >
                                    <option value="" disabled>
                                        Select months (1-6)
                                    </option>
                                    {[...Array(6)].map((_, index) => (
                                        <option key={index + 1} value={index + 1}>
                                            {index + 1} month{index > 0 && 's'}
                                        </option>
                                    ))}
                                </S.MonthsSelect>
                            </S.FormField>
                        )}
                    </>
                )}
                <S.ButtonContainer>
                    <S.SaveButton onClick={handleSave} disabled={!isFormComplete}>Save Changes</S.SaveButton>
                </S.ButtonContainer>
            </S.FormSection>
        </Modal>
    );
};

export default MaintenanceRecommendationModal;
