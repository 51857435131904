import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';

export const Container = styled.div`
    display flex;
    flex-direction: column;
    align-items: center;
`;

export const Title = styled.p`
    font-weight: bold;
    margin: 0;
`;

export const Subtitle = styled.p`
    margin: 15px 0 20px 0;
`;

export const TimeContainer = styled.div`
    display: flex;
`;

export const Time = styled.p`
    margin-bottom: 50px;
`;

export const ButtonContainer = styled.div`
    display: flex;

`;

export const DeleteButton = styled(Buttons.Button)`
    display: flex;
    justify-content: center;
    background-color: ${props => props.theme.buttons.red};
    color: ${props => props.theme.shades.white};
    margin-right: 20px;
    border: 2px solid ${props => props.theme.buttons.red};
    width: 150px;
    gap: 5px;
    
    p {
        margin: 0;
    }
`;

export const DeleteIcon = styled.svg`
    object-fit: contain;
    width: 24px;
    height: 24px;
`;

export const CancelButton = styled(Buttons.Button)`
    background-color: ${props => props.theme.buttons.blue};
    color: ${props => props.theme.shades.white};
    width: 150px;
`;
