import styled from 'styled-components';
import { Buttons, Spinner } from '@apps/common-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ErrorsContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: max(280px, 49%);
    flex: 2;
    gap: 10px;
    position: relative;
    align-items: flex-start;
`;

export const InsuranceContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;
    gap: 10px;
`;

export const Label = styled.p<{type?: 'errors' | 'insurance'}>`
    font-weight: bold;
    margin: 0 0 5px 0;
    color: ${(props) => {
        if (props.type === 'errors') return props.theme.shades.red;
        return 'black';
    }};
`;

export const RowItems = styled.div`
    display: flex;
    gap: 25px;
`;

export const Item = styled.div<{size?: 'small', color?: 'green' | 'red'}>`
    width: ${props => props.size === 'small' ? '80px' : '210px'};
    color: ${(props) => {
        if (props.color === 'green') return props.theme.text.success;
        if (props.color === 'red') return props.theme.text.error;
        return 'black';
    }};

    img {
        margin-left: 5px;
    }
`;

export const PencilButton = styled(Buttons.Button)`
    padding: 0;
    margin: 0;
    color: ${(props) => {
        if (props.color === 'green') return props.theme.text.success;
        if (props.color === 'red') return props.theme.text.error;
        return 'black';
    }};
`;

export const Error = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0;
`;

export const ErrorMessage = styled.p`
    margin: 0;
`;

export const ErrorIcon = styled(FontAwesomeIcon)<{criticalIssue?: boolean}>`
    color: ${props => props.criticalIssue ? props.theme.text.error : props.theme.text.black};
    margin-right: 5px;
`;

export const HeaderRow = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: flex-start;

    button {
        padding: 0 10px;
        margin: 0 0 0 10px;
    }
`;

export const StyledSpinner = styled(FontAwesomeIcon)<{loading: boolean}>`
    animation:  ${props => props.loading ? 'spin 1s linear infinite' : 'none'};
`;
