import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IPatientListUser, CareType } from '../../../../types/models';
import PatientStatusTag from '../../../../components/PatientStatusTag';
import * as S from '../index.styles';
import LinkToHealthie from '../../../../components/ExternalLinks/LinkToHealthie';
import { differenceInDays, format } from 'date-fns';
import { formatDateForPatientList } from '../../../../utils/dateUtils';
import { faCalendar, faCalendarCheck } from '@fortawesome/free-regular-svg-icons';

const StatusCell = ({ ptProgramStatus, rrtProgramStatus }: IPatientListUser) => {
    return (
        <S.TableColumnStatus>
            {ptProgramStatus
                && (
                <PatientStatusTag
                  patientStatus={ptProgramStatus}
                  careType={CareType.PT}
                />
            )}
            {rrtProgramStatus
                && (
                <PatientStatusTag
                  patientStatus={rrtProgramStatus}
                  careType={CareType.RRT}
                />
            )}
        </S.TableColumnStatus>
    );
};

const PatientCell = ({ fullName, diagnosis }: IPatientListUser) => (
    <S.TableColumn data-tag="allowRowEvents">
        <S.TableText data-tag="allowRowEvents" style={{ fontWeight: 'bold' }}>{fullName}</S.TableText>
        <S.PatientPrimaryDiagnosis data-tag="allowRowEvents">{diagnosis}</S.PatientPrimaryDiagnosis>
    </S.TableColumn>
);

const ViewMoreCell = ({ healthieId, lastViewedAt }: IPatientListUser) => (
    <S.TableColumn data-tag="allowRowEvents">
        <S.TableText data-tag="allowRowEvents">{lastViewedAt ? `${differenceInDays(new Date(), new Date(lastViewedAt))}  days ago` : 'Never viewed'}</S.TableText>
        <S.HealthieLinkContainter>
            <LinkToHealthie data-tag="allowRowEvents" healthieId={healthieId} />
        </S.HealthieLinkContainter>
    </S.TableColumn>
);

const AppointmentCell = ({ nextAppointment, lastAppointment }: {nextAppointment: string, lastAppointment:string}) => {
    return (
        <S.TableColumn data-tag="allowRowEvents">
            <S.TableText data-tag="allowRowEvents">
                <FontAwesomeIcon data-tag="allowRowEvents" icon={faCalendar as IconProp} />
                {nextAppointment ? formatDateForPatientList(new Date(nextAppointment)) : 'No next appt'}
            </S.TableText>
            <S.TableText data-tag="allowRowEvents">
                <FontAwesomeIcon data-tag="allowRowEvents" icon={faCalendarCheck as IconProp} />
                {lastAppointment ? formatDateForPatientList(new Date(lastAppointment)) : 'No last appt'}
            </S.TableText>
        </S.TableColumn>
    );
};

export default {
    StatusCell,
    PatientCell,
    ViewMoreCell,
    AppointmentCell,
};
