import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useActionLoader } from '../../hooks/useActionLoader';
import { fetchBillableSessionTotalSummary } from '../../state/reducers/billing';
import { RootState } from '../../state/store';
import { BillableSessionStatus, BillableSessionType, IFetchedBillableSession, IFetchedGenericSession, UserRoles } from '../../types/models';
import * as S from './index.styles';
import { formatBillableSessionDate, getMinutesStringFromSeconds } from './Utils/utils';
import { useModal } from '@apps/common-ui';
import DeleteEntryModal from './DeleteEntryModal';
import EditEntryModal from './EditEntryModal';
import AddEntryModal from './AddEntryModal';
import { ApiResponse, RequestMethod, useApiRequest } from '../../hooks/useApiRequest';
import { ReactComponent as DiscardIcon } from '../../assets/DiscardIcon.svg';
import { ReactComponent as EditIcon } from '../../assets/EditIcon.svg';

const TimeTracking = () => {
    const { user: provider } = useSelector((state: RootState) => state.session);
    const { patientId } = useParams<{ patientId: string }>();
    const { totalBillingSummary } = useSelector((state: RootState) => state.billing);
    const { callAction: fetchTotalSummary, done, loading } = useActionLoader(fetchBillableSessionTotalSummary);
    const { callApi, done: getSessionDone, loading: getSessionLoading } = useApiRequest<IFetchedBillableSession>(RequestMethod.GET);

    const [rowsExpanded, setRowsExpanded] = useState(Array(totalBillingSummary.length).fill(false));
    const selectedSession = useRef<IFetchedBillableSession>();

    const { closeModal: closeDeleteModal, isOpen: isDeleteModalOpen, openModal: openDeleteModal } = useModal();
    const { closeModal: closeEditModal, isOpen: isEditModalOpen, openModal: openEditModal } = useModal();
    const { closeModal: closeAddModal, isOpen: isAddModalOpen, openModal: openAddModal } = useModal();

    // sets the selected row to be expanded or collapsed and updates the rowsExpanded array in state
    const updateRowsExpanded = (index: number) => {
        const newRowsExpanded = [...rowsExpanded];
        newRowsExpanded[index] = !newRowsExpanded[index];
        setRowsExpanded(newRowsExpanded);
    };

    const getProviderRole = (providerRole: string): string => {
        if (!providerRole) {
            return '';
        }
        let roles = '';
        providerRole.split('_').forEach((word) => {
            roles += word[0];
        });
        return roles;
    };

    const getStatusString = (status: BillableSessionStatus): string => {
        if (status === BillableSessionStatus.MANUALLY_CREATED) {
            return 'Manual';
        } else if (status === BillableSessionStatus.EDITED) {
            return 'Edited';
        } else if (status === BillableSessionStatus.HEALTHIE_CHARTING_NOTE) {
            return 'Healthie Charting Note';
        }
        return '';
    };

    const refreshSessions = () => {
        if (patientId) {
            fetchTotalSummary(patientId);
        }
    };

    const editClicked = (genericSession: IFetchedGenericSession) => {
        callApi(`/users/${patientId}/billable-sessions/${genericSession.sessionId}`).then(({ response, error }: ApiResponse<IFetchedBillableSession>) => {
            if (!error.error && !error.message) {
                selectedSession.current = response.data;
                openEditModal();
            }
        });
    };

    const deleteClicked = (genericSession: IFetchedGenericSession) => {
        callApi(`/users/${patientId}/billable-sessions/${genericSession.sessionId}`).then(({ response, error }: ApiResponse<IFetchedBillableSession>) => {
            if (!error.error && !error.message) {
                selectedSession.current = response.data;
                openDeleteModal();
            }
        });
    };

    useEffect(() => {
        if (!done && !loading && patientId) {
            fetchTotalSummary(patientId);
        }
    }, [patientId, totalBillingSummary]);

    return (
        <>
            {isDeleteModalOpen && (
            <DeleteEntryModal
              showModal={isDeleteModalOpen}
              dismissModal={closeDeleteModal}
              billableSession={selectedSession.current}
              refreshSessions={refreshSessions}
            />
            )}
            {isEditModalOpen && (
            <EditEntryModal
              showModal={isEditModalOpen}
              dismissModal={closeEditModal}
              billableSession={selectedSession.current}
              refreshSessions={refreshSessions}
            />
            )}
            {isAddModalOpen && (
                <AddEntryModal
                  showModal={isAddModalOpen}
                  dismissModal={closeAddModal}
                  patientId={patientId || ''}
                  provider={provider}
                  refreshSessions={refreshSessions}
                />
            )}
            <S.Container>
                <S.PageContent>
                    <S.TitleContainer>
                        <S.PageTitle>Billing</S.PageTitle>
                        <S.AddSessionButton onClick={() => openAddModal()}>
                            Add Async Time Entry
                        </S.AddSessionButton>
                    </S.TitleContainer>
                    <S.TableContainer>
                        <S.Table>
                            <thead>
                                <S.TableRow>
                                    <S.TableHeader alignLeft>Date</S.TableHeader>
                                    <S.TableHeader>Sync Time (min)</S.TableHeader>
                                    <S.TableHeader>Async Time (min)</S.TableHeader>
                                    <S.TableHeader minWidth="150px">Provider</S.TableHeader>
                                    <S.TableHeader minWidth="100px">Status</S.TableHeader>
                                    <S.TableHeader minWidth="30px" />
                                    <S.TableHeader minWidth="30px" />
                                    <S.TableHeader darkBackground borderLeft borderTop>Total Time</S.TableHeader>
                                    <S.TableHeader darkBackground borderRight borderTop>Total Units</S.TableHeader>
                                </S.TableRow>
                            </thead>
                            {totalBillingSummary.map((month, index) => (
                                <tbody>
                                    <S.TableRow
                                      onClick={() => updateRowsExpanded(index)}
                                      borderLight
                                      highlightOnHover
                                    >
                                        <S.TableData bold alignLeft>
                                            {month.calendarMonth}
                                        </S.TableData>
                                        <S.TableData><b>{Math.floor(month.totalSyncSeconds / 60)}</b></S.TableData>
                                        <S.TableData><b>{Math.floor(month.totalAsyncSeconds / 60)}</b></S.TableData>
                                        <S.TableData minWidth="150px" />
                                        <S.TableData minWidth="60px" />
                                        <S.TableData minWidth="30px" />
                                        <S.TableData minWidth="30px" />
                                        <S.TableData
                                          darkBackground
                                          minWidth="130px"
                                          borderLeft
                                          borderBottom={!rowsExpanded[totalBillingSummary.length - 1] && index === totalBillingSummary.length - 1}
                                          redText={totalBillingSummary[index].totalSeconds >= totalBillingSummary[index].maxSeconds}
                                        >
                                            {Math.floor(totalBillingSummary[index].totalSeconds / 60)}/{totalBillingSummary[index].maxSeconds / 60}
                                        </S.TableData>
                                        <S.TableData
                                          darkBackground
                                          minWidth="130px"
                                          borderRight
                                          borderBottom={!rowsExpanded[totalBillingSummary.length - 1] && index === totalBillingSummary.length - 1}
                                          redText={totalBillingSummary[index].totalBillingUnits >= totalBillingSummary[index].maxBillingUnits}
                                        >
                                            {totalBillingSummary[index].totalBillingUnits}/{totalBillingSummary[index].maxBillingUnits}
                                        </S.TableData>
                                    </S.TableRow>
                                    {rowsExpanded[index] && totalBillingSummary[index].sessions
                                .filter(session => session.durationSeconds > 0)
                                .map((session, sessionIndex) => (
                                    <S.TableRow borderLight highlightOnHover>
                                        <S.TableData>{formatBillableSessionDate(session.calendarDate)}</S.TableData>
                                        <S.TableData>{session.type === BillableSessionType.SYNC ? getMinutesStringFromSeconds(session.durationSeconds) : ''}</S.TableData>
                                        <S.TableData>{session.type === BillableSessionType.ASYNC ? getMinutesStringFromSeconds(session.durationSeconds) : ''}</S.TableData>
                                        <S.TableData>{session.providerName}, {getProviderRole(session.providerRole)}</S.TableData>
                                        <S.TableData minWidth="60px">{session.status ? getStatusString(session.status) : ''}</S.TableData>
                                        <S.TableData minWidth="30px">
                                            {session.type === BillableSessionType.ASYNC && session.status !== BillableSessionStatus.HEALTHIE_CHARTING_NOTE && (
                                                <S.EditSessionButton
                                                  onClick={() => {
                                                    editClicked(session);
                                                  }}
                                                >
                                                    <S.ButtonIcon><EditIcon aria-label="EditIcon" /></S.ButtonIcon>
                                                </S.EditSessionButton>
                                            )}
                                        </S.TableData>
                                        <S.TableData minWidth="30px">
                                            {session.type === BillableSessionType.ASYNC && session.status !== BillableSessionStatus.HEALTHIE_CHARTING_NOTE && (
                                                <S.DiscardSessionButton
                                                  onClick={() => {
                                                    deleteClicked(session);
                                                  }}
                                                >
                                                    <S.ButtonIcon><DiscardIcon aria-label="DiscardIcon" /></S.ButtonIcon>
                                                </S.DiscardSessionButton>
                                            )}
                                        </S.TableData>
                                        <S.TableData
                                          darkBackground
                                          borderLeft
                                          borderBottom={index === totalBillingSummary.length - 1 && sessionIndex === totalBillingSummary[index].sessions.filter(s => s.durationSeconds > 0).length - 1}
                                        />
                                        <S.TableData
                                          darkBackground
                                          borderRight
                                          borderBottom={index === totalBillingSummary.length - 1 && sessionIndex === totalBillingSummary[index].sessions.filter(s => s.durationSeconds > 0).length - 1}
                                        />
                                    </S.TableRow>
                                ))}
                                </tbody>
                            ))}
                        </S.Table>
                    </S.TableContainer>
                </S.PageContent>
            </S.Container>
        </>
    );
};

export default TimeTracking;
