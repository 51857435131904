import React, { useEffect, useState } from 'react';
import { useModal } from '@apps/common-ui';
import * as S from './index.styles';
import * as SParent from '../../index.styles';
import { RootState } from '../../../../../../state/store';
import { useSelector } from 'react-redux';
import { MaintenanceRecommendationType, UserRoles } from '../../../../../../types/models';
import MaintenanceRecommendationSummary from './MaintenanceRecommendationSummary';
import MaintenanceRecommendationModal from './MaintenanceRecommendationModal';
import { isPTDischarged, isPTMaintenance, isRRTDischarged, isRRTMaintenance } from '../../../../patientUtils';

const MaintenanceRecommendations = () => {
    const { closeModal, isOpen, openModal } = useModal();

    const { currentPatient } = useSelector((store: RootState) => store.coaching);
    const { userBillableRole } = useSelector((store: RootState) => store.session);

    const pcmRecommendations = currentPatient?.pcmMaintenanceRecommendation || null;
    const ptRecommendations = currentPatient?.ptMaintenanceRecommendation || null;

    const [formManuallyClosed, setFormManuallyClosed] = useState(false);

    useEffect(() => {
        if (!formManuallyClosed && currentPatient?.midpointApptScheduled) {
            if (userBillableRole === UserRoles.PHYSICAL_THERAPIST && !ptRecommendations && !isPTMaintenance(currentPatient) && !isPTDischarged(currentPatient)) {
                openModal();
                setFormManuallyClosed(false);
            } else if (userBillableRole === UserRoles.RESPIRATORY_THERAPIST && !pcmRecommendations && !isRRTMaintenance(currentPatient) && !isRRTDischarged(currentPatient)) {
                openModal();
                setFormManuallyClosed(false);
            }
        }
    }, [currentPatient?.midpointApptScheduled, userBillableRole, ptRecommendations, pcmRecommendations, openModal, formManuallyClosed]);

    const handleCloseForm = () => {
        setFormManuallyClosed(true);
        closeModal();
    };

    return (
        <>
            {isOpen && (
                <MaintenanceRecommendationModal
                  showModal={isOpen}
                  dismissModal={handleCloseForm}
                  formData={userBillableRole === UserRoles.PHYSICAL_THERAPIST ? ptRecommendations : pcmRecommendations}
                  userBillableRole={userBillableRole}
                />
            )}

            <SParent.PatientInfoRow>
                <S.MaintenanceInfoBlock>
                    <MaintenanceRecommendationSummary
                      maintenanceType={MaintenanceRecommendationType.PT}
                      maintenanceRecFormData={ptRecommendations}
                      openModalFunction={openModal}
                      userBillableRole={userBillableRole}
                    />
                </S.MaintenanceInfoBlock>
                <S.MaintenanceInfoBlock>
                    <MaintenanceRecommendationSummary
                      maintenanceType={MaintenanceRecommendationType.PCM}
                      maintenanceRecFormData={pcmRecommendations}
                      openModalFunction={openModal}
                      userBillableRole={userBillableRole}
                    />
                </S.MaintenanceInfoBlock>
            </SParent.PatientInfoRow>
        </>

    );
};

export default MaintenanceRecommendations;
