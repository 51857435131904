import styled from 'styled-components';
import { PatientStatus } from '../../types/models';

export const PatientStatusTag = styled.div<{patientStatus?: PatientStatus}>`
  display: flex;
  align-items: center;
  width: 90px;
  height: 18px;
  border-radius: 9px;
  font-size: 14px;
  font-weight: bold;
  margin: 5px 5px 5px 0px;
  padding: 9px 5px;
  color: ${props => {
        switch (props.patientStatus) {
            case 'MNT_ACTIVE':
                return props.theme.statusTag.teal;
            case 'MNT_PAUSED':
                return props.theme.statusTag.orange;
            case 'MNT_DISCHARGED':
                return props.theme.statusTag.red;
            default:
                return 'white';
        }
    }};
  background-color: ${props => {
        switch (props.patientStatus) {
            case 'VPR_ACTIVE':
                return props.theme.statusTag.teal;
            case 'VPR_PAUSED':
                return props.theme.statusTag.orange;
            case 'VPR_DISCHARGED':
                return props.theme.statusTag.red;
            default:
                return 'white';
        }
    }};
  border: 2px solid ${props => {
        switch (props.patientStatus) {
            case 'MNT_ACTIVE':
                return props.theme.statusTag.teal;
            case 'MNT_PAUSED':
                return props.theme.statusTag.orange;
            case 'MNT_DISCHARGED':
                return props.theme.statusTag.red;
            default:
                return 'none';
        }
    }};

  img {
    flex-shrink: 0;
  }

  div {
    text-align: center;
    flex-grow: 1;
  }
`;
