var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
export var SidebarContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: flex-start;\n    min-width: 160px;\n    min-height: 100vh;\n    top: 0;\n    left: 0;\n    background-color: ", ";\n    box-sizing: border-box;\n    color: ", ";\n    svg {\n        margin-bottom: 15px;\n    }\n\n    @media (max-width: ", ") {\n        z-index: 4;\n        position: fixed;\n        min-height: ", ";\n        width: 100%;\n        transition: 0.3s ease-in-out;\n        overflow: hidden;\n    }\n"], ["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: flex-start;\n    min-width: 160px;\n    min-height: 100vh;\n    top: 0;\n    left: 0;\n    background-color: ", ";\n    box-sizing: border-box;\n    color: ", ";\n    svg {\n        margin-bottom: 15px;\n    }\n\n    @media (max-width: ", ") {\n        z-index: 4;\n        position: fixed;\n        min-height: ", ";\n        width: 100%;\n        transition: 0.3s ease-in-out;\n        overflow: hidden;\n    }\n"])), function (props) { return props.theme.primary.medium; }, function (props) { return props.theme.text.white; }, function (props) { return props.theme.screen.medium; }, function (props) { return props.isSidebarOpen ? '100%' : '60px'; });
export var SidebarLinks = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    width: 100%;\n    height: 100%;\n    padding: 30px 0 0 0;\n    overflow-y: auto;\n    overflow-x: hidden;\n\n    button {\n        margin-left: 20px;\n        max-width: 80%;\n    }\n\n    svg {\n        align-self: center;\n    }\n\n    @media (max-width: ", ") {\n        svg {\n            position: absolute;\n            bottom: 15px;\n            left: 50%;\n            transform: translateX(-50%);\n        }\n    }\n"], ["\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    width: 100%;\n    height: 100%;\n    padding: 30px 0 0 0;\n    overflow-y: auto;\n    overflow-x: hidden;\n\n    button {\n        margin-left: 20px;\n        max-width: 80%;\n    }\n\n    svg {\n        align-self: center;\n    }\n\n    @media (max-width: ", ") {\n        svg {\n            position: absolute;\n            bottom: 15px;\n            left: 50%;\n            transform: translateX(-50%);\n        }\n    }\n"])), function (props) { return props.theme.screen.medium; });
export var SidebarLink = styled(NavLink)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    color: ", ";\n    margin-bottom: 10px;\n    text-decoration: none;\n    border-left: 5px solid transparent;\n    box-shadow: none;\n    cursor: pointer;\n    text-align: left;\n    padding: 5px 0 5px 20px;\n\n    &.active, :hover {\n        font-weight: bold;\n        border-left: 5px solid ", ";\n        background: rgba(255, 255, 255, 0.1);\n    }\n\n\n    button {\n        margin-left: 0;\n    }\n\n    svg {\n        margin: 0 10px 0 0;\n    }\n\n    @media (max-width: ", ") {\n        svg {\n            position: static;\n        }\n    }\n"], ["\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    color: ", ";\n    margin-bottom: 10px;\n    text-decoration: none;\n    border-left: 5px solid transparent;\n    box-shadow: none;\n    cursor: pointer;\n    text-align: left;\n    padding: 5px 0 5px 20px;\n\n    &.active, :hover {\n        font-weight: bold;\n        border-left: 5px solid ", ";\n        background: rgba(255, 255, 255, 0.1);\n    }\n\n\n    button {\n        margin-left: 0;\n    }\n\n    svg {\n        margin: 0 10px 0 0;\n    }\n\n    @media (max-width: ", ") {\n        svg {\n            position: static;\n        }\n    }\n"])), function (props) { return props.theme.text.white; }, function (props) { return props.theme.buttons.blue; }, function (props) { return props.theme.screen.medium; });
export var SidebarDivider = styled.hr(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    border-top: 1px solid ", ";\n    margin-bottom: 15px;\n    border-bottom: none;\n    margin: 5px 25px;\n"], ["\n    border-top: 1px solid ", ";\n    margin-bottom: 15px;\n    border-bottom: none;\n    margin: 5px 25px;\n"])), function (props) { return props.theme.primary.light; });
export var SidebarSubHeading = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    color: ", " !important;\n    font-size: 16px;\n    text-transform: uppercase;\n    margin-bottom: 15px;\n    cursor: default;\n    padding-left: 25px;\n"], ["\n    color: ", " !important;\n    font-size: 16px;\n    text-transform: uppercase;\n    margin-bottom: 15px;\n    cursor: default;\n    padding-left: 25px;\n"])), function (props) { return props.theme.text.lightBlue; });
export var SidebarExternalLinkContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n\n    a {\n        width: 100%;\n    }\n\n    svg {\n        margin-left: 20px;\n    }\n\n    @media (max-width: ", ") {\n        svg {\n            display: block;\n            position: static;\n        }\n    }\n"], ["\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n\n    a {\n        width: 100%;\n    }\n\n    svg {\n        margin-left: 20px;\n    }\n\n    @media (max-width: ", ") {\n        svg {\n            display: block;\n            position: static;\n        }\n    }\n"])), function (props) { return props.theme.screen.medium; });
export var SidebarHamburgerContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    display: flex;\n    width: 100%;\n    align-items: center;\n\n    svg, p {\n        margin: 20px 0 0 20px;\n        color: ", ";\n        font-size: 16px;\n    }\n"], ["\n    display: flex;\n    width: 100%;\n    align-items: center;\n\n    svg, p {\n        margin: 20px 0 0 20px;\n        color: ", ";\n        font-size: 16px;\n    }\n"])), function (props) { return props.theme.text.white; });
export var SidebarPrivacyLinks = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    font-size: 12px;\n    margin-bottom: 10px;\n\n    a {\n        margin: 0 0 10px 10px;\n        color: ", ";\n        &.active {\n            background: none;\n            font-weight: normal;\n            border-left: 5px solid transparent;\n        }\n    }\n\n    @media (max-width: ", ") {\n        justify-content: flex-start;\n    }\n"], ["\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    font-size: 12px;\n    margin-bottom: 10px;\n\n    a {\n        margin: 0 0 10px 10px;\n        color: ", ";\n        &.active {\n            background: none;\n            font-weight: normal;\n            border-left: 5px solid transparent;\n        }\n    }\n\n    @media (max-width: ", ") {\n        justify-content: flex-start;\n    }\n"])), function (props) { return props.theme.shades.grey; }, function (props) { return props.theme.screen.medium; });
export var SidebarLinksContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    display: flex;\n    flexDirection: column;\n    justifyContent: space-between;\n"], ["\n    display: flex;\n    flexDirection: column;\n    justifyContent: space-between;\n"])));
export var Badge = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    background: ", ";\n    color: ", ";\n    border-radius: 4px;\n    padding: 2px 8px;\n    font-size: 12px;\n    margin-left: 10px;\n    font-weight: bold;\n"], ["\n    background: ", ";\n    color: ", ";\n    border-radius: 4px;\n    padding: 2px 8px;\n    font-size: 12px;\n    margin-left: 10px;\n    font-weight: bold;\n"])), function (props) { return props.theme.buttons.blue; }, function (props) { return props.theme.text.white; });
export var ErrorBadge = styled.span(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    background: ", ";\n    color: ", ";\n    border-radius: 2px;\n    padding: 0 6px 0 6px;\n    font-size: 12px;\n    font-weight: bold;\n"], ["\n    background: ", ";\n    color: ", ";\n    border-radius: 2px;\n    padding: 0 6px 0 6px;\n    font-size: 12px;\n    font-weight: bold;\n"])), function (props) { return props.theme.buttons.red; }, function (props) { return props.theme.text.white; });
export var SidebarLogo = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    padding-left: 40px;\n    transform: scale(1.3);\n"], ["\n    padding-left: 40px;\n    transform: scale(1.3);\n"])));
export var Icon = styled.img(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n    margin-left: 10px;\n"], ["\n    margin-left: 10px;\n"])));
export var SidebarSubLink = styled(SidebarLink)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    padding: 0 5px 0 30px;\n    font-size: 14px;\n    justify-content: space-between!important;\n\n    &.active, :hover {\n        background: rgba(255, 255, 255, 0.0);\n        border: none;\n        font-weight: normal;\n    }\n\n    &.isActive {\n        font-weight: bold;\n        padding-left: 25px;\n        border-left: 5px solid ", ";\n    }\n\n"], ["\n    padding: 0 5px 0 30px;\n    font-size: 14px;\n    justify-content: space-between!important;\n\n    &.active, :hover {\n        background: rgba(255, 255, 255, 0.0);\n        border: none;\n        font-weight: normal;\n    }\n\n    &.isActive {\n        font-weight: bold;\n        padding-left: 25px;\n        border-left: 5px solid ", ";\n    }\n\n"])), function (props) { return props.theme.buttons.blue; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
