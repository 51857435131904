import { TableStyles } from 'react-data-table-component';
import { theme } from '@apps/common-ui';

export const patientIssuesTableStyleOverrides: TableStyles = {
    cells: {
        style: {
            fontSize: '16px',
            margin: '10px 0px 10px 0px',
            padding: '0px 0px 0px 15px',
        }
    },
    headRow: {
        style: {
            borderRadius: '9px 9px 0 0',
            backgroundColor: theme.table.borderDark
        }
    },
    headCells: {
        style: {
            fontSize: '16px',
            fontWeight: 'bold',
            color: theme.text.white,
            paddingLeft: '8px',
            paddingRight: '0px',
            margin: 0,
            justifyContent: 'flex-start',
        }
    },
    responsiveWrapper: {
        style: {
            overflowY: 'auto',
            cursor: 'pointer'
        }
    }
};

export const patientsTableStyleOverrides: TableStyles = {
    cells: {
        style: {
            fontSize: '16px',
            margin: '10px 0px 10px 0px',
            padding: '0px 0px 0px 15px',
        }
    },
    headRow: {
        style: {
            borderRadius: '9px 9px 0 0',
            backgroundColor: theme.table.blue
        }
    },
    headCells: {
        style: {
            fontSize: '16px',
            fontWeight: 'bold',
            color: theme.text.white,
            paddingLeft: '8px',
            paddingRight: '0px',
            margin: 0,
            justifyContent: 'flex-start',
        }
    },
    responsiveWrapper: {
        style: {
            overflowY: 'auto',
            cursor: 'pointer'
        }
    }
};

export const tableStyleOverrides: TableStyles = {
    responsiveWrapper: {
        style: {
            overflowY: 'auto',
            cursor: 'pointer'
        }
    }
};
