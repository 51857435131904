import React from 'react';
import * as S from '../index.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import PatientStatusTag from '../../../../components/PatientStatusTag';
import { IPatientError, CareType } from '../../../../types/models';
import LinkToHealthie from '../../../../components/ExternalLinks/LinkToHealthie';

const StatusCell = ({ ptProgramStatus: ptPatientStatus, rrtProgramStatus: rrtPatientStatus }: IPatientError) => {
    return (
        <S.TableColumnStatus>
            {ptPatientStatus
                && (
                <PatientStatusTag
                  patientStatus={ptPatientStatus}
                  careType={CareType.PT}
                />
            )}
            {rrtPatientStatus
                && (
                <PatientStatusTag
                  patientStatus={rrtPatientStatus}
                  careType={CareType.RRT}
                />
            )}
        </S.TableColumnStatus>
    );
};

const PatientCell = ({ healthieFirstName: firstName, healthieLastName: lastName, email }: IPatientError) => (
    <S.TableColumn data-tag="allowRowEvents">
        <S.TableText data-tag="allowRowEvents" style={{ fontWeight: 'bold' }}>{firstName} {lastName}</S.TableText>
        <S.PatientPrimaryDiagnosis data-tag="allowRowEvents">{email}</S.PatientPrimaryDiagnosis>
    </S.TableColumn>
);

const ViewMoreCell = ({ healthieId, isCoach, isPrimaryCoach }: IPatientError) => (
    <S.TableColumn>
        <S.HealthieLinkContainter>
            <LinkToHealthie healthieId={healthieId} />
        </S.HealthieLinkContainter>
        {(isCoach && !isPrimaryCoach) && <S.ViewMoreTag>Other</S.ViewMoreTag>}
    </S.TableColumn>
);

export default {
    StatusCell,
    PatientCell,
    ViewMoreCell
};
