import React from 'react';
import * as SParent from '../../index.styles';
import * as S from './index.styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../state/store';
import { formatDateForPatientPage, getColorForPlanOfCare, isBoldForPlanOfCare, monthsOld } from '../../../../../../../../utils/dateUtils';

const getPlanAgeMessage = (startDate: string): string => {
    const today = new Date();
    if (!startDate) {
        return 'No plan created';
    }
    const startDateObj = new Date(startDate);

    const planAgeInMonths = monthsOld(startDateObj);
    const planAgeInYears = Math.floor(planAgeInMonths / 12);

    const isExactMonth = today.getDate() === startDateObj.getDate() && planAgeInMonths >= 1;
    const isExactYear = planAgeInMonths % 12 === 0 && isExactMonth;
    const isToday = planAgeInMonths === 0;

    if (isToday) {
        return 'This plan was created today';
    }

    if (planAgeInYears >= 1) {
        const yearLabel = planAgeInYears === 1 ? 'year' : 'years';
        return `This plan is ${isExactYear ? '' : 'over '}${planAgeInYears} ${yearLabel} old`;
    }

    const monthLabel = planAgeInMonths === 1 ? 'month' : 'months';
    return `This plan is ${isExactMonth ? '' : 'over '}${planAgeInMonths} ${monthLabel} old`;
};

const CarePlan = () => {
    const { currentPatient } = useSelector((state: RootState) => state.coaching);
    const latestPlanStartDate = currentPatient?.rrtPlanOfCareLastUpdated ?? '';
    const planAgeMessage = getPlanAgeMessage(latestPlanStartDate);

    const planColor = getColorForPlanOfCare(false, latestPlanStartDate);
    const isBoldText = isBoldForPlanOfCare(false, latestPlanStartDate);

    return (
        <SParent.Container>
            <SParent.HeaderRow>
                <S.Header>
                    Care Plan
                </S.Header>
            </SParent.HeaderRow>
            {currentPatient && (
                <S.Body>
                    {currentPatient.rrtPlanOfCareStartDate
                    && <span>Created on {formatDateForPatientPage(new Date(currentPatient.rrtPlanOfCareStartDate))}</span>}
                    {currentPatient.rrtPlanOfCareLastUpdated
                    && <span>Last updated on {formatDateForPatientPage(new Date(currentPatient.rrtPlanOfCareLastUpdated))}</span>}
                    <S.AgeText color={planColor} isBold={isBoldText}>
                        {planAgeMessage}
                    </S.AgeText>
                </S.Body>
            )}
        </SParent.Container>
    );
};

export default CarePlan;
