import React from 'react';
import { Spinner } from '@apps/common-ui';

const DivPaddedSpinner = () => (
    <div style={{ padding: '60px' }}>
        <Spinner />
    </div>
);

export default DivPaddedSpinner;
