import { Spinner, Buttons, Inputs } from '@apps/common-ui';
import styled from 'styled-components';

interface FilterButtonProps {
    isActive?: boolean;
}

export const UsersListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 20px;
`;

export const IssuesHeader = styled.h1`
    margin-bottom: 0;
`;

export const PatientHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
`;

export const HeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    h1 {
        font-weight: normal;
    }
`;

export const FilterButtonContainer = styled.form`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 35%;
`;

export const FilterButton = styled(Buttons.Button)<FilterButtonProps>`
    height: 35px;
    white-space: nowrap;
    border-radius: 43px;
    padding: 2px 15px 2px 15px;
    color: ${props => props.isActive ? props.theme.text.white : props.theme.text.black};
    background: ${props => props.isActive ? props.theme.buttons.blue : props.theme.buttons.lightGrey};
    border: 1px solid ${props => props.isActive ? props.theme.buttons.blue : props.theme.buttons.grey};
    font-weight: ${props => props.isActive ? 'bold' : 'normal'};
`;

export const ViewedLabel = styled.p`
    margin: 3px 0px;
    color: ${props => props.theme.shades.grey};
`;

export const AllPatientLabel = styled.p`
    margin: 0 0 20px 0;
`;

export const FilterSelect = styled(Inputs.Select)`
    width: 200px;
    padding: 5px;
    margin: 10px 0;
`;
