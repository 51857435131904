import React from 'react';
import LinkToExternalSite from '..';
import { ReactComponent as HealthieIcon } from '../../../assets/healthieIcon.svg';

const LinkToHealthie = ({ healthieId }: { healthieId: string }) => {
    const healthieUrl = process.env.REACT_APP_HEALTHIE_URL;
    if (!healthieUrl) {
        return null;
    }
    return (
        <LinkToExternalSite url={`${healthieUrl}/users/${healthieId}`} text="Healthie">
            <HealthieIcon aria-label="healthieIcon" />
        </LinkToExternalSite>
    );
};

export default LinkToHealthie;
