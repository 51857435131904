import React, { ReactNode } from 'react';
import * as S from './index.styles';
import { ReactComponent as LinkArrow } from '../../assets/LinkArrow.svg';

const LinkToExternalSite = ({ url, text, children }: { url: string; text: string; children: ReactNode }) => (
    <S.LinkRow href={url} target="_blank" rel="noreferrer">
        <S.Img>{children}</S.Img>
        <S.LinkText>{text}</S.LinkText>
        <S.LinkArrow><LinkArrow aria-label="LinkArrow" /></S.LinkArrow>
    </S.LinkRow>
);

export default LinkToExternalSite;
