import styled from 'styled-components';

export const Table = styled.table`
    min-width: max(280px, 49%);
    margin: 0 min(10px, 2%) min(20px, 2%) 0;
    height: 25px;
    text-align: left;

    td {
        padding-left: 0;
        padding-right: 4px;
    }
    table {
        border-collapse: collapse;
    }
`;
