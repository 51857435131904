import React, { useEffect, useRef, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { IPatientListUser, IPatientError, UserRoles, IErrorType, PatientStatus } from '../../types/models';
import * as S from './index.styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { useNavigate, useParams } from 'react-router';
import { useActionLoader } from '../../hooks/useActionLoader';
import { fetchErrors, fetchPatients, PatientsFetchParams } from '../../state/reducers/coaching';
import { useSearchParams } from 'react-router-dom';
import { Buttons, Inputs } from '@apps/common-ui';
import { patientsTableStyleOverrides, patientIssuesTableStyleOverrides } from '../../api/utils/tables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faRefresh, faArrowDownLong, faArrowUpLong } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { errorColumns, errorColumnsCollapsed, getPatientColumnsForRole, CollapsedTableData, ErrorMessages } from './components/tableUtils';
import { format, set } from 'date-fns';
import DivPaddedSpinner from './components/DivPaddedSpinner';

export enum PatientFilters {
    ALL = 'ALL',
    UNASSIGNED = 'UNASSIGNED',
    ASSIGNED_TO_ME = 'ASSIGNED_TO_ME',
    DISCHARGED = 'DISCHARGED',
    OTHER = 'OTHER'
}

export enum PatientStatusFilters {
    ALL = 'ALL',
    VPR = 'VPR',
    MNT = 'MNT',
    PAUSED = 'PAUSED',
    TODAY = 'TODAY',
    TOMORROW = 'TOMORROW',
}

export enum PatientSortValues {
    FULL_NAME = 'fullName',
    LAST_VIEWED_AT = 'lastViewedAt',
    PLAN_OF_CARE_END_DATE = 'planOfCareEndDate',
    NEXT_PT_APPOINTMENT = 'nextPtAppointment',
    NEXT_RT_APPOINTMENT = 'nextRrtAppointment',
    REMAINING_PT_BILLING_SECONDS_UNTIL_NEXT_UNIT = 'ptRemainingBillingSecondsUntilNextUnit',
    REMAINING_RRT_BILLING_SECONDS_UNTIL_NEXT_UNIT = 'rrtRemainingBillingSecondsUntilNextUnit',
}

const PatientsList = () => {
    const { patientListUsers, patientErrors, patientTotalErrors, patientTotalCriticalErrors, patientTotalStandardErrors, totalPatients } = useSelector((state: RootState) => state.coaching);
    const { user, userBillableRole } = useSelector((state: RootState) => state.session);
    const navigate = useNavigate();
    const { callAction: getPatients, loading: patientsLoading, done: patientsDone } = useActionLoader(fetchPatients);
    const { callAction: getErrors, loading: errorsLoading } = useActionLoader(fetchErrors);
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState(50);
    const [searchParams, setSearchParams] = useSearchParams();
    const [patientSearchVal, setPatientSearchVal] = useState(searchParams.get('search') || '');
    const [patientListFilter, setPatientListFilter] = useState<PatientFilters>(searchParams.get('filter') as PatientFilters || (userBillableRole ? PatientFilters.ASSIGNED_TO_ME : PatientFilters.ALL));
    const [patientStatusFilter, setPatientStatusFilter] = useState<PatientStatusFilters>(searchParams.get('status') as PatientStatusFilters || PatientStatusFilters.ALL);
    const [errorFilter, setErrorFilter] = useState<IErrorType | 'ALL'>('ALL');
    const [filteredErrors, setFilteredErrors] = useState<IPatientError[]>([]);
    const [showErrors, setShowErrors] = useState(true);
    const sortValue = useRef<PatientSortValues | null>(PatientSortValues.FULL_NAME);
    const sortDirection = useRef<'ASC' | 'DESC'>('ASC');
    const patientsTableColumns = getPatientColumnsForRole(userBillableRole);
    const collapsedTableData : CollapsedTableData = [{
        totalErrors: patientTotalErrors,
        totalCriticalErrors: patientTotalCriticalErrors,
        totalStandardErrors: patientTotalStandardErrors
    }];

    const loadPatients = () => {
        if (user) {
            const params: PatientsFetchParams = {
                page: page - 1, // spring data page is 0 indexed
                pageSize,
                search: patientSearchVal,
                ptProgramStatus: [],
                rrtProgramStatus: [],
                assignmentType: undefined,
                nextPtAppointment: '',
                nextRrtAppointment: '',
                sortBy: '',
                orderBy: '',
                programStatusInclusion: 'AND'
            };
            // All is all filters
            // my is all with assignment type primary
            // other is all with assignment type other
            // discharged is all with discharged status
            if (userBillableRole === UserRoles.PHYSICAL_THERAPIST || patientListFilter === PatientFilters.ALL) {
                if (patientStatusFilter === PatientStatusFilters.TODAY) {
                    params.nextPtAppointment = format(new Date(), 'yyyy-MM-dd');
                } else if (patientStatusFilter === PatientStatusFilters.TOMORROW) {
                    const tomorrow = new Date();
                    tomorrow.setDate(tomorrow.getDate() + 1);
                    params.nextPtAppointment = format(tomorrow, 'yyyy-MM-dd');
                } else if (patientListFilter === PatientFilters.DISCHARGED) {
                    params.ptProgramStatus.push(PatientStatus.VPR_DISCHARGED);
                    params.ptProgramStatus.push(PatientStatus.MNT_DISCHARGED);
                }

                if (patientStatusFilter === PatientStatusFilters.VPR) {
                    params.ptProgramStatus.push(PatientStatus.VPR_ACTIVE);
                } else if (patientStatusFilter === PatientStatusFilters.MNT) {
                    params.ptProgramStatus.push(PatientStatus.MNT_ACTIVE);
                } else if (patientStatusFilter === PatientStatusFilters.PAUSED) {
                    params.ptProgramStatus.push(PatientStatus.VPR_PAUSED);
                    params.rrtProgramStatus.push(PatientStatus.MNT_PAUSED);
                } else if (patientListFilter !== PatientFilters.DISCHARGED) {
                    params.ptProgramStatus.push(PatientStatus.VPR_ACTIVE, PatientStatus.MNT_ACTIVE, PatientStatus.MNT_PAUSED, PatientStatus.VPR_PAUSED);
                }
            }
            if (userBillableRole === UserRoles.RESPIRATORY_THERAPIST || patientListFilter === PatientFilters.ALL) {
                if (patientStatusFilter === PatientStatusFilters.TODAY) {
                    params.nextRrtAppointment = format(new Date(), 'yyyy-MM-dd');
                } else if (patientStatusFilter === PatientStatusFilters.TOMORROW) {
                    const tomorrow = new Date();
                    tomorrow.setDate(tomorrow.getDate() + 1);
                    params.nextRrtAppointment = format(tomorrow, 'yyyy-MM-dd');
                } else if (patientListFilter === PatientFilters.DISCHARGED) {
                    params.rrtProgramStatus.push(PatientStatus.VPR_DISCHARGED);
                    params.rrtProgramStatus.push(PatientStatus.MNT_DISCHARGED);
                }
                // show all vpr patients by default
                if (patientStatusFilter === PatientStatusFilters.VPR) {
                    params.rrtProgramStatus.push(PatientStatus.VPR_ACTIVE);
                } else if (patientStatusFilter === PatientStatusFilters.MNT) {
                    params.rrtProgramStatus.push(PatientStatus.MNT_ACTIVE);
                } else if (patientStatusFilter === PatientStatusFilters.PAUSED) {
                    params.rrtProgramStatus.push(PatientStatus.VPR_PAUSED);
                    params.rrtProgramStatus.push(PatientStatus.MNT_PAUSED);
                } else if (patientListFilter !== PatientFilters.DISCHARGED) {
                    params.rrtProgramStatus.push(PatientStatus.VPR_ACTIVE, PatientStatus.MNT_ACTIVE, PatientStatus.MNT_PAUSED, PatientStatus.VPR_PAUSED);
                }
            }
            if (patientListFilter === PatientFilters.ASSIGNED_TO_ME) {
                params.assignmentType = 'PRIMARY';
            } else if (patientListFilter === PatientFilters.OTHER) {
                params.assignmentType = 'OTHER';
            } else if (patientListFilter === PatientFilters.ALL) {
                params.programStatusInclusion = 'OR';
            }
            params.sortBy = sortDirection.current;
            params.orderBy = sortValue.current || PatientSortValues.FULL_NAME;
            getPatients(params);
        }
    };

    const loadErrors = () => {
        if (user && userBillableRole) {
            getErrors();
        }
    };

    useEffect(() => {
        loadErrors();
    }, [user]);

    useEffect(() => {
        if (errorFilter === 'ALL') {
            setFilteredErrors(patientErrors);
        } else {
            setFilteredErrors(patientErrors.filter((patient) => patient.errors.some((e) => e.type === errorFilter)));
        }
    }, [patientErrors, errorFilter]);

    useEffect(() => {
        loadPatients();
    }, [user, page, pageSize, patientListFilter, patientStatusFilter]);

    // This is to update the page number when the user navigates to a page with a page number in the url
    useEffect(() => {
        const pageParam = searchParams.get('page');
        if (pageParam && pageParam !== page?.toString()) {
            const pageNum = parseInt(pageParam, 10);
            setPage(pageNum);
        }
        setPatientSearchVal(searchParams.get('search') || '');
        if (searchParams.get('filter')) {
            setPatientListFilter(searchParams.get('filter') as PatientFilters);
        }
        if (searchParams.get('status')) {
            setPatientStatusFilter(searchParams.get('status') as PatientStatusFilters);
        }
    }, [searchParams]);

    // Event handlers for the datatable
    const handlePageChange = (pageNum: number) => {
        if (page !== pageNum) {
            setSearchParams({ page: `${pageNum}`, search: patientSearchVal, filter: patientListFilter });
        }
    };

    const handlePageSizeChange = (size: number, pageNum: number) => {
        if (size !== pageSize) {
            setPageSize(size);
        }
    };

    const handleSearch = (e: React.SyntheticEvent) => {
        e.preventDefault();
        loadPatients();
        setSearchParams({ page: '1', search: patientSearchVal, filter: patientListFilter });
    };

    const handleRowClick = (id: string) => {
        navigate(`/patients/${id}`);
    };

    const handleSort = (column: any, direction: string) => {
        switch (column.sortField) {
            case 'name':
                sortValue.current = PatientSortValues.FULL_NAME;
                break;
            case 'planOfCareEndDate':
                sortValue.current = PatientSortValues.PLAN_OF_CARE_END_DATE;
                break;
            case 'nextAppointment':
                if (userBillableRole === UserRoles.PHYSICAL_THERAPIST) {
                    sortValue.current = PatientSortValues.NEXT_PT_APPOINTMENT;
                } else {
                    sortValue.current = PatientSortValues.NEXT_RT_APPOINTMENT;
                }
                break;
            case 'billing':
                if (userBillableRole === UserRoles.PHYSICAL_THERAPIST) {
                    sortValue.current = PatientSortValues.REMAINING_PT_BILLING_SECONDS_UNTIL_NEXT_UNIT;
                } else {
                    sortValue.current = PatientSortValues.REMAINING_RRT_BILLING_SECONDS_UNTIL_NEXT_UNIT;
                }
                break;
            case 'lastViewedAt':
                sortValue.current = PatientSortValues.LAST_VIEWED_AT;
                break;
            default:
                sortValue.current = PatientSortValues.FULL_NAME;
        }
        sortDirection.current = direction === 'asc' ? 'ASC' : 'DESC';
        loadPatients();
    };

    const getTotalPatientText = () => {
        if (patientStatusFilter === PatientStatusFilters.VPR) {
            return 'Active vPR patients: ';
        } else if (patientStatusFilter === PatientStatusFilters.MNT) {
            return 'Active maintenance patients: ';
        } else if (patientStatusFilter === PatientStatusFilters.PAUSED) {
            return 'Paused vPR or maintenance patients: ';
        }
        return 'Total Patients: ';
    };

    return (
        <S.UsersListContainer>
            {(patientErrors.length === 0) && (
                <p><strong>No issues to address</strong></p>
            )}
            {(patientListFilter === PatientFilters.ASSIGNED_TO_ME) && patientErrors.length > 0 && (
                <>
                    <S.HeaderRow>
                        <S.IssuesHeader>
                            Error Tracker
                        </S.IssuesHeader>
                    </S.HeaderRow>

                    {showErrors ? (
                        <>
                            <S.HeaderRow>
                                <S.AllPatientLabel>All Patients</S.AllPatientLabel>
                            </S.HeaderRow>
                            <S.HeaderRow>
                                <S.FilterSelect
                                  value={errorFilter}
                                  onChange={(e) => setErrorFilter(e.target.value as IErrorType | 'ALL')}
                                >
                                    <option value="ALL">All Issues</option>
                                    {Object.entries(ErrorMessages).map(([key, message]) => (
                                        <option key={key} value={key}>
                                            {message}
                                        </option>
                                    ))}
                                </S.FilterSelect>
                                <Buttons.Button buttonType="tertiary" onClick={loadErrors}>
                                    Refresh
                                    <FontAwesomeIcon icon={faRefresh as IconProp} />
                                </Buttons.Button>
                            </S.HeaderRow>
                            <DataTable
                              columns={errorColumns({ showErrors, setShowErrors })}
                              data={filteredErrors}
                              highlightOnHover
                              striped
                              customStyles={patientIssuesTableStyleOverrides}
                              progressPending={errorsLoading}
                              progressComponent={<DivPaddedSpinner />}
                              onRowClicked={(row: IPatientError, e) => handleRowClick(row.id)}
                            />
                        </>
                    ) : (
                        <DataTable
                          columns={errorColumnsCollapsed({ showErrors, setShowErrors, patientsWithErrors: patientErrors.length })}
                          data={collapsedTableData}
                          highlightOnHover
                          striped
                          customStyles={patientIssuesTableStyleOverrides}
                          onChangePage={handlePageChange}
                          onChangeRowsPerPage={handlePageSizeChange}
                          progressPending={errorsLoading}
                          progressComponent={<DivPaddedSpinner />}
                        />
                    )}
                </>
            )}
            <S.PatientHeader>
                <S.HeaderRow>
                    <h1>
                        {patientListFilter === PatientFilters.ALL && <span>All Active </span>}
                        {patientListFilter === PatientFilters.ASSIGNED_TO_ME && <span>My </span>}
                        {patientListFilter === PatientFilters.DISCHARGED && <span>All Discharged </span>}
                        {patientListFilter === PatientFilters.OTHER && <span>Other </span>}
                        Patients
                    </h1>
                </S.HeaderRow>
                <S.HeaderRow>
                    {patientListFilter !== PatientFilters.DISCHARGED && (
                    <Inputs.OptionSelector
                      width="60%"
                      value={patientStatusFilter}
                      onSelect={(value) => {
                        setPatientStatusFilter(value as PatientStatusFilters);
                        setSearchParams({ page: '1', search: patientSearchVal, filter: patientListFilter, status: value });
                      }}
                      options={[
                            {
                                value: PatientStatusFilters.ALL,
                                display: 'All'
                            },
                            {
                                value: PatientStatusFilters.TODAY,
                                display: 'Today'
                            },
                            {
                                value: PatientStatusFilters.TOMORROW,
                                display: 'Tomorrow'
                            },
                            {
                                value: PatientStatusFilters.VPR,
                                display: 'VPR'
                            },
                            {
                                value: PatientStatusFilters.MNT,
                                display: 'Maintenance'
                            },
                            {
                                value: PatientStatusFilters.PAUSED,
                                display: 'Paused'
                            }
                        ]}
                    />
                    )}
                    <S.FilterButtonContainer onSubmit={handleSearch}>
                        <Inputs.Input
                          onChange={(e) => setPatientSearchVal(e.target.value)}
                          value={patientSearchVal}
                          placeholder="Search"
                          style={{ width: '200px', margin: '0' }}
                        />
                        <Buttons.Button
                          type="submit"
                          size="small"
                          style={{ marginLeft: '10px' }}
                        >Search
                        </Buttons.Button>
                        <Buttons.Button buttonType="secondary" size="small" onClick={() => setPatientSearchVal('')}>
                            Clear Search
                            <FontAwesomeIcon icon={faTimes as IconProp} />
                        </Buttons.Button>
                    </S.FilterButtonContainer>
                </S.HeaderRow>
                <S.HeaderRow>
                    <p>{getTotalPatientText()} {!patientsLoading && (<span>{totalPatients}</span>)}</p>
                </S.HeaderRow>
            </S.PatientHeader>
            <DataTable
              columns={patientsTableColumns}
              data={patientListUsers}
              highlightOnHover
              paginationServer
              pagination
              striped
              paginationDefaultPage={page || 1}
              customStyles={patientsTableStyleOverrides}
              onRowClicked={(row: IPatientListUser, e) => handleRowClick(row.id)}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePageSizeChange}
              paginationPerPage={pageSize}
              paginationTotalRows={totalPatients}
              progressPending={patientsLoading}
              progressComponent={<DivPaddedSpinner />}
              onSort={(column, direction) => handleSort(column, direction)}
              paginationRowsPerPageOptions={[10, 25, 50]}
            />
        </S.UsersListContainer>
    );
};

export default PatientsList;
