import { Auth } from 'aws-amplify';
import React, { useCallback, useEffect, useState } from 'react';
import { Buttons } from '@apps/common-ui';
import { InputContainer, Label, Input } from '@apps/common-ui/src/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Spinner from '@apps/common-ui/src/Spinner';
import { useActionLoader } from '../../../hooks/useActionLoader';
import { RootState } from '../../../state/store';
import * as S from './index.styles';
import { loginUser } from '../../../state/reducers/session/actions';
import Icon from '../../../assets/icon.png';

function LoginForm() {
    const { callAction: setUser, loading: loginLoading } = useActionLoader(loginUser);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const { loggedIn } = useSelector((state: RootState) => state.session);
    const navigate = useNavigate();

    useEffect(() => {
        if (loggedIn) {
            setLoading(true);
            navigate('/patients');
        }
    }, [loggedIn]);

    // Show loading while login is in progress
    useEffect(() => {
        if (loginLoading) {
            setLoading(true);
        }
    }, [loginLoading]);

    const schema = yup.object({
        email: yup.string().email('Invalid email').required('Email is required'),
        password: yup.string().required('Password is required'),
    }).required();

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const login = async (data: any) => {
        setError('');
        const { email, password } = data;
        const result = await Auth.signIn(email, password).catch(err => {
            switch (err.code) {
                case 'NotAuthorizedException':
                case 'UserNotFoundException':
                case 'InvalidParameterException':
                    setError('Invalid login credentials');
                    break;
                default:
                    setError('Unknown error');
                    break;
            }
        });
        if (result) {
            setUser({
                user: { email },
            });
        }
    };

    return (
        <S.LoginContainer>
            <img src={Icon} alt="BreatheSuite logo" width="200" />
            <h2>BreatheSuite Coaching Dashboard</h2>
            <form onSubmit={handleSubmit(login)}>
                <InputContainer>
                    <Label htmlFor="email">Email</Label>
                    <Input
                      {...register('email')}
                      placeholder="Email"
                      id="email"
                    />
                    {!!errors.email && <S.Error>{errors.email.message?.toString()}</S.Error>}
                </InputContainer>
                <InputContainer>
                    <Label htmlFor="password">Password</Label>
                    <Input
                      {...register('password')}
                      type="password"
                      placeholder="password"
                      id="password"
                    />
                    {!!errors.password && <S.Error>{errors.password.message?.toString()}</S.Error>}
                </InputContainer>
                {error && <S.Error>{error}</S.Error>}
                <Buttons.FullWidthButton
                  style={{ margin: '10px' }}
                  type="submit"
                  disabled={loading}
                >
                    {loading
                        ? <Spinner small />
                        : 'Login'}
                </Buttons.FullWidthButton>
            </form>
        </S.LoginContainer>
    );
}

export default LoginForm;
