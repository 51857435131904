import { BillingStateColors } from '../pages/Patient/components/CareTeamTimer/utils';
import { IPatientListUser } from '../types/models';

export const getSecondsUntilNextUnit = (currentSeconds: number, maxSeconds: number, maxBillingUnits: number) => {
    const secondsPerUnit = maxSeconds / maxBillingUnits;
    return (secondsPerUnit - (currentSeconds % secondsPerUnit));
};

export const getColorForBilling = (currentSeconds: number, maxSeconds: number, maxBillingUnits: number, isPT: boolean) => {
    const nextUnitProgressSeconds = currentSeconds % (maxSeconds / maxBillingUnits);
    if (currentSeconds >= maxSeconds) {
        return BillingStateColors.BLACK;
    }
    if (isPT) {
        // 20 minutes unit
        if (nextUnitProgressSeconds >= 1200) {
            return BillingStateColors.BLACK;
        } else if (nextUnitProgressSeconds >= 900) {
            return BillingStateColors.RED;
        } else if (nextUnitProgressSeconds >= 600) {
            return BillingStateColors.YELLOW;
        } else {
            return BillingStateColors.BLACK;
        }
    }
    // 30 minutes unit
    if (nextUnitProgressSeconds >= 1800) {
        return BillingStateColors.BLACK;
    } else if (nextUnitProgressSeconds >= 1440) {
        return BillingStateColors.RED;
    } else if (nextUnitProgressSeconds >= 1080) {
        return BillingStateColors.YELLOW;
    } else {
        return BillingStateColors.BLACK;
    }
};

export const getIsBoldForBilling = (currentSeconds: number, maxSeconds: number, maxBillingUnits: number, isPT: boolean) => {
    const nextUnitProgressSeconds = currentSeconds % (maxSeconds / maxBillingUnits);
    if (currentSeconds >= maxSeconds) {
        return true;
    }
    if (isPT) {
        // 20 minutes unit
        if (nextUnitProgressSeconds >= 1200) {
            return true;
        } else if (nextUnitProgressSeconds >= 900) {
            return true;
        } else if (nextUnitProgressSeconds >= 600) {
            return true;
        } else {
            return false;
        }
    }
    // 30 minutes unit
    if (nextUnitProgressSeconds >= 1800) {
        return true;
    } else if (nextUnitProgressSeconds >= 1440) {
        return true;
    } else if (nextUnitProgressSeconds >= 1080) {
        return true;
    } else {
        return false;
    }
};

export const getColorForBillingFromMinutesToNextUnit = (minutesToNextUnit: number, isPT: boolean) => {
    if (isPT) {
        // 20 minutes unit
        if (minutesToNextUnit >= 20) {
            return BillingStateColors.BLACK;
        } else if (minutesToNextUnit >= 15) {
            return BillingStateColors.RED;
        } else if (minutesToNextUnit >= 10) {
            return BillingStateColors.YELLOW;
        } else {
            return BillingStateColors.GREY;
        }
    }
    // 30 minutes unit
    if (minutesToNextUnit >= 30) {
        return BillingStateColors.BLACK;
    } else if (minutesToNextUnit >= 24) {
        return BillingStateColors.RED;
    } else if (minutesToNextUnit >= 18) {
        return BillingStateColors.YELLOW;
    } else {
        return BillingStateColors.GREY;
    }
};

export const hasReachedPtMaxBillingUnits = (user: IPatientListUser) => {
    const userBillingUnits = Math.floor(user.ptTotalBillingSeconds / user.ptBillingSecondsPerUnit);

    if (user.flatRatePTBilling) {
        return (userBillingUnits >= 2);
    } else {
        return (userBillingUnits >= user.ptMaxBillingUnits);
    }
};

export const hasReachedRrtMaxBillingUnits = (user: IPatientListUser) => {
    return Math.floor(user.rrtTotalBillingSeconds / user.rrtBillingSecondsPerUnit) >= user.rrtMaxBillingUnits;
};

export const getPtMaxBillingUnitsForUser = (user: IPatientListUser) => {
    return user.flatRatePTBilling ? 2 : user.ptMaxBillingUnits;
};

export const getPtMaxBillingSeconds = (user: IPatientListUser) => {
    return user.flatRatePTBilling ? (2 * 20 * 60) : user.ptMaxBillingSeconds;
};
