import styled from 'styled-components';
import { TimerState } from './useTimerStateMachine';
import { Buttons, Spinner, Card } from '@apps/common-ui';
import { BillingStateColors } from './utils';

export const TimerContainer = styled(Card)<{expanded?: boolean}>`
  display: ${props => props.expanded ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: ${props => props.expanded ? 'flex-start' : 'center'};
  align-items: center;
  width: 100%;
  margin: 10px 0;
  position: relative;
  padding: 10px;
`;

export const TimerNumbers = styled.h3<{state: TimerState}>`
  color: ${(props) => props.state === TimerState.RUNNING ? 'black' : props.theme.danger.medium};
  margin: 5px;
  font-family: monospace;
`;

export const TimerButton = styled(Buttons.Button)<{state: TimerState}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 7px 7px;
  width: 100%;
  margin: 0;
  padding: 5px;
  min-height: 40px;
  background-color: ${(props) => props.state === TimerState.RUNNING ? props.theme.buttons.red : props.theme.buttons.blue};
  border-color: ${(props) => props.state === TimerState.RUNNING ? props.theme.buttons.red : props.theme.buttons.blue};
`;

export const Timer = styled.div`
  border: 1px solid black;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MonthlySummary = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const BillingSummary = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Text = styled.p<{error?: boolean}>`
  font-size: 16px;
  margin: 0 0 3px 0;
  ${props => props.error && `color: ${props.theme.danger.medium};`}
`;

export const ButtonText = styled(Text)`
  margin-bottom: 0px;
`;

export const MonthText = styled(Text)`
  font-weight: bold;
`;

export const MonthlySummaryValues = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const SmallText = styled(Text)`
  font-size: 10px;
`;

export const Error = styled(Text)`
  color: ${props => props.theme.danger.medium};
  white-space: wrap;
  font-size: 12px;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 5px;
`;

export const TotalContainer = styled.div<{color: BillingStateColors}>`
  display: flex;
  justify-content: center;
  gap: 5px;
  width: 100%;
  background-color: ${props => {
        switch (props.color) {
            case BillingStateColors.BLACK:
                return props.theme.shades.black;
            case BillingStateColors.YELLOW:
                return props.theme.shades.yellow;
            case BillingStateColors.RED:
                return props.theme.shades.red;
            default:
                return props.theme.shades.grey;
        }
    }};
  color: ${props => props.color === BillingStateColors.GREY ? props.theme.text.black : props.theme.text.white};
  border-radius: 5px;
  padding: 5px 5px;
  p {
    font-weight: ${
    props => props.color !== BillingStateColors.GREY ? 'bold' : 'normal'
};
  }
`;

export const UnitSummary = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const CurrentMinutes = styled.span<{color: BillingStateColors}>`
  padding: 0 3px;
  font-weight: bold;
  font-size: 16px;
  color: ${
    props => {
        switch (props.color) {
            case BillingStateColors.BLACK:
                return props.theme.shades.black;
            case BillingStateColors.YELLOW:
                return props.theme.shades.yellow;
            case BillingStateColors.RED:
                return props.theme.shades.red;
            default:
                return props.theme.shades.grey;
        }
    }
}
`;
