const enum CourseStateColors {
    YELLOW = 'yellow',
    RED = 'red',
    BLACK = 'black'
}

export const getColorForCourses = (educationCompletedPercentage: number, educationActivity30Days: number) => {
    if (educationCompletedPercentage === 100 && educationActivity30Days < 3) {
        return CourseStateColors.BLACK;
    } else if (educationActivity30Days >= 3) {
        return CourseStateColors.BLACK;
    } else if (educationActivity30Days === 1 || educationActivity30Days === 2) {
        return CourseStateColors.YELLOW;
    } else {
        return CourseStateColors.RED;
    }
};
