import { theme, Buttons } from '@apps/common-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

interface TableTextProps {
    color?: keyof typeof theme.tableText;
    isBold?: boolean
}

export const PatientName = styled.p`
    font-weight: bold;
    font-size: 16px;
    margin: 5px 0px 5px 0px;
`;

export const PatientPrimaryDiagnosis = styled.p`
    color: ${props => props.theme.shades.darkGrey};
    margin: 5px 0px;
    padding: 5px 0px;
`;

export const PatientDetailsButton = styled(Buttons.Button)`
    font-weight: normal;
    text-decoration: none;
`;

export const TableText = styled.p<TableTextProps>`
  display: flex;
  align-items: center;
  margin: 5px 0px;
  padding: 5px 0px;
  color: ${(props) => (props.color ? props.theme.tableText[props.color] : 'inherit')};
  font-weight: ${(props) => (props.isBold ? 'bold' : 'normal')};

  svg, img {
    margin: 0 5px 0 5px;
    width: 16px;
  }
`;

export const TableTextBillingSeconds = styled(TableText)`
    padding-bottom: 0;
    margin-bottom: 0;
`;

export const ErrorsColumnTableText = styled(TableText)<TableTextProps>`
    margin: 5px 0px;
    padding: 5px 0px;
`;

export const TableColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
`;

export const TableColumnStatus = styled(TableColumn)`
    padding-top: 5px;
    gap: 10px;
`;

export const TableColumnHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 10px 0 10px 8px;

`;

export const TableColumnHeader = styled.p`
    font-size: 16px;
    font-weight: bold;
    margin: 0px 5px 0px 0px;
`;

export const TableColumnSubheader = styled.p`
    font-size: 12px;
    font-weight: bold;
    margin: 0px;
`;

export const AlertsRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 3px 0px;
    width: 100%;
`;

export const Img = styled.img`
    object-fit: contain;
    object-position: left;
`;

export const ViewMoreTag = styled.div`
    margin: 10px 0px 10px 45px;
    background-color: ${theme.shades.lightGrey};
    color: ${theme.text.white};
    border-radius: 20px;
    padding: 5px 15px;
`;

export const HealthieLinkContainter = styled.div`
    margin-top: 10px;
`;
