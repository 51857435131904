import React from 'react';
import * as S from './index.styles';
import { PatientStatus, CareType, UserRoles } from '../../types/models';
import { ReactComponent as WhitePlayIcon } from '../../assets/whitePlayIcon.svg';
import { ReactComponent as WhitePauseIcon } from '../../assets/whitePauseIcon.svg';
import { ReactComponent as WhiteSquareIcon } from '../../assets/whiteSquareIcon.svg';
import { ReactComponent as FilledPlayIcon } from '../../assets/filledPlayIcon.svg';
import { ReactComponent as FilledPauseIcon } from '../../assets/filledPauseIcon.svg';
import { ReactComponent as FilledSquareIcon } from '../../assets/filledSquareIcon.svg';

type Props = {
    patientStatus: PatientStatus;
    careType: CareType;
    style?: React.CSSProperties;
}

const PatientStatusTag = ({ patientStatus, careType, style }: Props) => {
    let content: React.ReactNode;
    switch (patientStatus) {
        case PatientStatus.VPR_ACTIVE:
            content = (
                <>
                    <WhitePlayIcon aria-label="whitePlayIcon" />
                    <div>{careType} vPR</div>
                </>
            );
            break;
        case PatientStatus.VPR_PAUSED:
            content = (
                <>
                    <WhitePauseIcon aria-label="whitePauseIcon" />
                    <div>{careType} vPR</div>
                </>
            );
            break;
        case PatientStatus.VPR_DISCHARGED:
            content = (
                <>
                    <WhiteSquareIcon aria-label="whiteSquareIcon" />
                    <div>{careType} vPR</div>
                </>
            );
            break;
        case PatientStatus.MNT_ACTIVE:
            content = (
                <>
                    <FilledPlayIcon aria-label="filledPlayIcon" />
                    <div>{careType} MNT</div>
                </>
            );
            break;
        case PatientStatus.MNT_PAUSED:
            content = (
                <>
                    <FilledPauseIcon aria-label="filledPauseIcon" />
                    <div>{careType} MNT</div>
                </>
            );
            break;
        case PatientStatus.MNT_DISCHARGED:
            content = (
                <>
                    <FilledSquareIcon aria-label="filledSquareIcon" />
                    <div>{careType} MNT</div>
                </>
            );
            break;
        default:
            content = null;
    }

    return (
        <S.PatientStatusTag
          patientStatus={patientStatus}
          style={style}
        >
            {content}
        </S.PatientStatusTag>
    );
};
export default PatientStatusTag;
