import { Card, theme } from '@apps/common-ui';
import styled from 'styled-components';
import { DayCountColors } from '.';

interface DayCountProps {
  color?: DayCountColors;
}

export const SummaryContainer = styled(Card)<{expanded?: boolean}>`
  display: ${props => props.expanded ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: ${props => props.expanded ? 'flex-start' : 'center'};
  align-items: center;
  width: 100%;
  margin: 0 0 10px 0;
  position: relative;
  padding: 0;
`;

export const SummaryHeader = styled.p`
  width: 100%;
  padding: 0 5px;
  margin: 5px;
  font-size: 14px;
  font-weight: 700;
`;

export const Current98976Summary = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DaysCount = styled.div<DayCountProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 30px;
  background-color: ${props => {
        switch (props.color) {
            case DayCountColors.GREEN:
                return theme.shades.green;
            case DayCountColors.YELLOW:
                return theme.shades.yellow;
            default:
                return theme.shades.red;
        }
    }};
  color: ${props => {
        switch (props.color) {
            case DayCountColors.YELLOW:
                return theme.text.black;
            default:
                return theme.text.white;
        }
    }};
  padding: 0 10px;
`;

export const DaysCountText = styled.p<{bold?: boolean}>`
  font-size: 16px;
  font-weight: bold;
  margin: 0;

`;

export const DaysAway = styled.div<DayCountProps>`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
  padding: 0 10px;
  background-color: ${props => {
        switch (props.color) {
            case DayCountColors.GREEN:
                return `${theme.shades.green}10`;
            case DayCountColors.YELLOW:
                return `${theme.shades.yellow}10`;
            default:
                return `${theme.shades.red}10`;
        }
    }};
`;

export const DaysRemainingCount = styled.p<DayCountProps>`
  font-weight: bold;
  font-size: 16px;
  margin: 0 auto;
  color: ${
    props => {
        switch (props.color) {
            case DayCountColors.GREEN:
                return theme.shades.green;
            case DayCountColors.YELLOW:
                return theme.shades.yellow;
            default:
                return theme.shades.red;
        }
    }
}`;

export const DaysRemainingText = styled.p`
  font-size: 12px;
  margin: 0;
  max-width: 75px;
`;

export const PeriodDateRow = styled.div<DayCountProps>`
  display: flex;
  background-color: ${props => {
        switch (props.color) {
            case DayCountColors.GREEN:
                return `${theme.shades.green}10`;
            case DayCountColors.YELLOW:
                return `${theme.shades.yellow}10`;
            case DayCountColors.RED:
                return `${theme.shades.red}10`;
            default:
                return `${theme.shades.white}`;
        }
    }};
  justify-content: space-between;
  gap: 5px;
  align-items: flex-start;
  width: 100%;
  padding: 10px 10px 0 10px;
`;

export const PeriodDate = styled.p`
  max-width: 25px;
  font-weight: bold;
  font-size: 12px;
  margin: 0 auto;
  text-align: center;
`;

export const PeriodDateText = styled.p`
  font-size: 12px;
  margin: 0;
  max-width: 75px;
`;

export const NextPeriodStartRow = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 10px 0 10px;
  color: ${props => props.theme.text.lightGrey};
`;

export const NextPeriodStartText = styled.p`
  font-size: 12px;
  font-style: italic;
  margin: 0;
  width: 100%;
`;
