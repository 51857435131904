import React, { useEffect, useState } from 'react';
import * as SParent from '../../index.styles';
import * as S from './index.styles';
import { IUser } from '../../../../../../types/models';
import { useOutletContext } from 'react-router';
import { differenceInYears } from 'date-fns';

function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function inchesToFeetInches(inches: number) {
    const feet = Math.floor(inches / 12);
    const remainingInches = inches % 12;
    return { feet, inches: remainingInches };
}

const HealthInfo = () => {
    const { patient } = useOutletContext<{ patient: IUser }>();
    const { feet, inches } = inchesToFeetInches(Number(patient.height));

    let age;
    if (patient.dateOfBirth) {
        age = differenceInYears(new Date(), new Date(patient.dateOfBirth));
    }

    const calculateBMI = () => {
        if (!patient.weight || !patient.height) {
            return 0;
        }
        const weight = patient.weight as unknown as number;
        const height = patient.height as unknown as number;
        const heightInM = height * 2.54 * 0.01;
        const weightInKg = weight * 0.453592;
        const bmi = weightInKg / (heightInM * heightInM);// unit is kg/m^2
        return parseFloat(bmi.toFixed(1));
    };

    const bmi = calculateBMI();

    return (
        <SParent.PatientInfoRow>
            <S.Table>
                <tbody>
                    <tr>
                        <td colSpan={2}><strong>Condition Information</strong></td>
                    </tr>
                    <tr>
                        <td colSpan={2}>{patient.primaryDiagnosis ? patient.primaryDiagnosis : ''}</td>
                    </tr>
                </tbody>
            </S.Table>
            <S.Table>
                <tbody>
                    <tr>
                        <td colSpan={2}><strong>Physical Information</strong></td>
                    </tr>
                    <tr>
                        <td style={{ verticalAlign: 'top', width: '50%' }}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Age</td>
                                        {age ? <td><strong>{age}</strong> ({patient.dateOfBirth})</td> : ''}
                                    </tr>
                                    <tr>
                                        <td>Sex</td>
                                        {patient.sexAssignedAtBirth ? <td><strong>{patient.sexAssignedAtBirth}</strong></td> : ''}
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td style={{ verticalAlign: 'top', width: '50%' }}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>BMI</td>
                                        <td><strong>{bmi > 0 ? bmi : ''}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>Height</td>
                                        {(feet > 0 || inches > 0) ? <td><strong>{feet} ft, {inches} in</strong></td> : ''}
                                    </tr>
                                    <tr>
                                        <td>Weight</td>
                                        {patient.weight ? <td><strong>{patient.weight} lbs</strong></td> : ''}
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </S.Table>
        </SParent.PatientInfoRow>
    );
};

export default HealthInfo;
